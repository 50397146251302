import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import styles from './Spinner.module.sass';

const SpinnerProps = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  color: PropTypes.string,
  className: PropTypes.string,
};

const SpinnerDefaultProps = {
  size: 'sm',
  color: null,
  className: null,
};

const Spinner = ({
  className,
  size,
  color,
}) => {

  const getSpinnerSize = (spinnerSize) => {
    let spinSize = null;

    switch (spinnerSize) {
      case 'xs':
        spinSize = 18;
        break;

      case 'sm':
        spinSize = 25;
        break;

      case 'md':
        spinSize = 35;
        break;

      case 'lg':
        spinSize = 45;
        break;

      default:
        spinSize = 25;
    }

    return spinSize;
  };

  return (
    <FontAwesomeIcon
      className={classNames(styles.spinnerLoader, {
        [className]: className,
      })}
      style={{ width: `${getSpinnerSize(size)}px`, height: `${getSpinnerSize(size)}px` }}
      color={color && color}
      icon={faCircleNotch}
      spin
    />
  );
};

Spinner.propTypes = SpinnerProps;
Spinner.defaultProps = SpinnerDefaultProps;

export default Spinner;
