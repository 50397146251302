import axios from 'utils/axios';

export const fetchSettingsIntegrationCodes = () =>
  axios.get('/settings/integration/codes');

export const fetchSettingsShop = () =>
  axios.get('/settings/shop/get');

export const fetchSettingsUser = () =>
  axios.get('/settings/user/get');

export const settingsUserEmailConfirm = (params) =>
  axios.get('/settings/user/email/confirm', {
    params,
  });

export const settingsUserPasswordChange = (params) =>
  axios.post('/settings/user/password/change', {
    params,
  });

export const settingsUserSave = (params) =>
  axios.post('/settings/user/save', {
    params,
  });

export const settingsShopSave = (params) =>
  axios.post('/settings/shop/save', {
    params,
  });

export const settingsEmailActivationResend = () =>
  axios.post('/settings/email/activation/resend');

export const settingsFeedUpdate = (params) =>
  axios.post('/settings/feed/update', params);
