import React from 'react';
import { Card, CardBody, Alert } from 'reactstrap';

const RecoverSent = () => (
  <div className="block-center mt-4 wd-xl">
    <Card>
      <CardBody>
        <Alert color="success">
          <h3 className="text-center" style={{ lineHeight: '35px' }}>Восстановление пароля</h3>
          <p>Инструкции по восстановления пароля отправлены на почту.</p>
        </Alert>
      </CardBody>
    </Card>
  </div>
);

export default RecoverSent;
