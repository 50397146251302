import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';

import './FullPageLoader.sass';

const FullPageLoader = props => (
  <div className="page-loader">
    <Helmet>
      <title>Boostmy.Email - авторизация</title>
    </Helmet>

    <FontAwesomeIcon icon={faSpinner} spin size="4x" />
  </div>
);

export default FullPageLoader;
