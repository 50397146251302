import { changeSetting } from 'store/actions/actions';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const AUTH_CHECKING_PENDING = 'AUTH_CHECKING_PENDING';
export const SET_USER_DATA = 'SET_USER_DATA';


export const userLogin = (token, isAdmin) => ({ type: USER_LOGIN, token, isAdmin });

export const userLogout = () => ({ type: USER_LOGOUT });

export const logoutAction = () => {
  return dispatch => {
    dispatch(userLogout());
    dispatch(changeSetting('isAdminAuth', false));
  };
};

export const authCheckingPending = pending => ({ type: AUTH_CHECKING_PENDING, pending });

export const setUserData = (id, name, email) => ({ type: SET_USER_DATA, id, name, email });
