import axios from 'utils/axios';

export const formRulesSave = (formId, formData) =>
  axios.post(`/forms/${formId}/rules/save`, formData);

export const formSave = (formId, formData) =>
  axios.post(`/forms/${formId}/form/save`, formData);

export const formDelete = (formId) =>
  axios.post(`/forms/${formId}/delete`);

export const formDuplicate = (formId) =>
  axios.post(`/forms/${formId}/duplicate`);

export const formStart = (formId) =>
  axios.post(`/forms/${formId}/start`);

export const formStop = (formId) =>
  axios.post(`/forms/${formId}/stop`);

export const formAdd = () =>
  axios.post(`/forms/add`);

export const fetchFormsRules = (formId) =>
  axios.get(`/forms/${formId}/rules`);

export const fetchFormData = (formId) =>
  axios.get(`/forms/${formId}/form`);

export const fetchFormsList = () =>
  axios.get('/forms/get');

export const letterUpload = (params) =>
  axios.post('/upload/letter', params);
