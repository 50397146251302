import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  fetchAdminUserInfo,
  adminUsersShopSave,
  adminUsersEmailResend,
  adminUsersFeedUpdate,
  fetchAdminPaymentHistory,
  adminUsersInfoBalanceAdd,
} from 'api';
import timeZones from 'google-timezones-json';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';

// Components
import ContentWrapper from 'components/ContentWrapper';
import FormValidator from 'components/FormValidator';
import SpinnerLoader from 'components/SpinnerLoader';
import ContentLoading from 'components/hoc/ContentLoading';
import Button from 'components/common/Forms/Button';

import './AdminUserProfilePage.sass';

class AdminUserProfilePage extends Component {

  state = {
    timeZones: Object.values(timeZones).map(zone => ({ id: uuid(), name: zone })),
    userShopSettingsForm: {
      shopName: '',
      mainDomain: '',
      YML_URL: '',
      timeZone: '',
      senderName: '',
      senderMail: '',
      mailStatus: ''
    },
    userPriceData: null,
    userShopYMLRequiredError: false,
    userShopSettingsLoading: true,
    resendActivationLetterLoading: false,
    userShopSettingsErrorLoading: false,
    updateFeedLoading: false,
    userShopSettingsSaveLoading: false,

    userPaymentsHistoryList: [],
    userPaymentsHistoryPerPage: 5,
    userPaymentsHistoryPage: 0,
    userPaymentInfoLoading: true,
    userPaymentsHistoryLoading: false,
    userPaymentInfoErrorLoading: false,
    userPaymentsHistoryErrorLoading: false,
    userVisitsPackages: [],
    userVisitsPackageValue: '',
    userVisitsBalance: null,
    userVisitsName: '',
    userVisitsPackagePayLoading: false
  };

  componentDidMount () {
    this.getUserShopSettings();
    this.getUserPaymentInfo();
    this.getUserPaymentsHistory();
  }

  getUserShopSettings = () => {
    this.setState({
      userShopSettingsLoading: true,
      userShopSettingsErrorLoading: false
    });

    fetchAdminUserInfo(this.props.match.params.userId)
      .then(({ data }) => {
        this.setState({
          userShopSettingsLoading: false
        });

        if (data.success) {
          this.setState({
            userShopSettingsForm: {
              shopName: data.data.shop.name,
              mainDomain: data.data.shop.domain,
              YML_URL: data.data.price ? data.data.price.url : '',
              timeZone: data.data.shop.timezone ? data.data.shop.timezone : '(GMT+03:00) Moscow+00 - Moscow',
              senderName: data.data.shop.senderName,
              senderMail: data.data.shop.senderMail,
              mailStatus: data.data.shop.mailStatus
            },
            userPriceData: data.data.price
          });
        } else {
          this.setState({
            userShopSettingsErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          userShopSettingsLoading: false,
          userShopSettingsErrorLoading: true
        });
      });
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
            ...this.state[form.name].errors,
            [input.name]: result
        }
      }
    });

    if (input.name === 'YML_URL') {
      if (value.length === 0) {
        this.setState({
          userShopYMLRequiredError: true
        });
      } else {
        this.setState({
          userShopYMLRequiredError: false
        })
      }
    }
  };

  hasError = (formName, inputName, method) => {
    return  this.state[formName] &&
            this.state[formName].errors &&
            this.state[formName].errors[inputName] &&
            this.state[formName].errors[inputName][method]
  };

  userShopSettingsFormSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    if (!hasError) {
      const { userShopSettingsForm } = this.state;

      this.setState({
        userShopSettingsSaveLoading: true
      });

      const params = {
        name: userShopSettingsForm.shopName,
        mirror: userShopSettingsForm.mainDomain,
        priceUrl: userShopSettingsForm.YML_URL,
        timezone: userShopSettingsForm.timeZone,
        senderName: userShopSettingsForm.senderName,
        senderMail: userShopSettingsForm.senderMail
      };

      adminUsersShopSave(this.props.match.params.userId, params)
        .then(({ data }) => {
          this.setState({
            userShopSettingsSaveLoading: false
          });

          if (data.success) {
            toast.success('Настройки успешно сохранены.');
          } else {
            toast.success('Ошибка при сохранении настроек');
          }
        })
        .catch(error => {
          this.setState({
            userShopSettingsSaveLoading: false
          });

          toast.success('Ошибка при сохранении настроек');
        });
    }
  };

  resendActivationLetter = () => {
    this.setState({
      resendActivationLetterLoading: true
    });

    adminUsersEmailResend(this.props.match.params.userId)
      .then(({ data }) => {
        this.setState({
          resendActivationLetterLoading: false
        });

        if (data.success) {
          toast.success('Письмо активации отправлено повторно.');
        } else {
          toast.error('Ошибка при отправке письма активации');
        }
      })
      .catch(error => {
        this.setState({
          resendActivationLetterLoading: false
        });

        toast.error('Ошибка при отправке письма активации');
      });
  };

  feedUpdate = () => {
    const { userShopSettingsForm, userShopYMLRequiredError } = this.state;

    if (userShopYMLRequiredError || !userShopSettingsForm.YML_URL) {
      this.setState({
        userShopYMLRequiredError: true
      });

      return;
    }

    this.setState({
      updateFeedLoading: true
    });

    setTimeout(() => {
      this.setState({
        updateFeedLoading: false
      });

      toast.success('Фид успешно обновлён.');
    }, 500);

    adminUsersFeedUpdate(this.props.match.params.userId, {
      priceUrl: userShopSettingsForm.YML_URL,
    })
      .then(({ data }) => {
        this.setState({
          updateFeedLoading: false
        });

        if (data.success) {
          toast.success('Фид успешно обновлён.');

          this.setState({
            userPriceData: data.data ? {
              lastUpdate: data.data.date,
              itemsCount: data.data.allItems,
              itemsAvailable: data.data.availableItems
            } : data.data
          });
        } else {
          toast.error('При обновлении фида произошла ошибка, попробуйте позже.');
        }
      })
      .catch(error => {
        this.setState({
          updateFeedLoading: false
        });

        toast.error('При обновлении фида произошла ошибка, попробуйте позже.');
      });
  };

  getTimeZonesOptionsList = () => {
    return this.state.timeZones.map(zone => (
      <option key={zone.id} value={zone.name}>{zone.name}</option>
    ));
  };

  getUserPaymentInfo = () => {
    this.setState({
      userPaymentInfoLoading: true,
      userPaymentInfoErrorLoading: false
    });

    setTimeout(() => {
      this.setState({
        userPaymentInfoLoading: false
      });
    }, 500);

    fetchAdminUserInfo(this.props.match.params.userId)
      .then(({ data }) => {
        this.setState({
          userPaymentInfoLoading: false
        });

        if (data.success) {
          this.setState({
            visitsPackages: data.data.packages,
            visitsPackageValue: data.data.packages[0].id,
            visitsBalance: data.data.billing.count,
            visitsName: data.data.billing.name
          });
        } else {
          this.setState({
            userPaymentInfoErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          userPaymentInfoLoading: false,
          userPaymentInfoErrorLoading: true
        });
      });
  };

  getUserPaymentsHistory = () => {
    this.setState({
      userPaymentsHistoryLoading: true,
      userPaymentsHistoryErrorLoading: false
    });

    fetchAdminPaymentHistory()
      .then(({ data }) => {
        this.setState({
          userPaymentsHistoryLoading: false
        });

        if (data.success) {
          this.setState({
            userPaymentsHistoryLoading: false,
            userPaymentsHistoryList: data.data
          });
        } else {
          this.setState({
            userPaymentsHistoryErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          userPaymentsHistoryLoading: false,
          userPaymentsHistoryErrorLoading: true
        });
      });
  };

  getUserPaymentsHistoryList = () => {
    const { userPaymentsHistoryList } = this.state;

    let renderUserPaymentsHistoryList = null;

    if (userPaymentsHistoryList.length === 0) {
      renderUserPaymentsHistoryList = (
        <tr>
          <td colSpan="3" className="text-center">
            Не найдено
          </td>
        </tr>
      );    

      return renderUserPaymentsHistoryList; 
    }

    renderUserPaymentsHistoryList = userPaymentsHistoryList.map((payment, i) => {
      return (
        <tr key={payment.id}>
          <td>{payment.name}</td>
          <td>{payment.price}</td>
          <td>{payment.date}</td>
        </tr>
      );
    });

    return renderUserPaymentsHistoryList;
  };

  getUserPaymentsHistoryListPagination = () => {
    const { 
      userPaymentsHistoryList, 
      userPaymentsHistoryPerPage, 
      userPaymentsHistoryPage 
    } = this.state;

    const pageQuantity = Math.ceil(userPaymentsHistoryList.length / userPaymentsHistoryPerPage);
    let renderUserPaymentsHistoryPagination = null;

    renderUserPaymentsHistoryPagination = (
      <Pagination>
        <PaginationItem>
          <PaginationLink first onClick={() => this.setUserPaymentsHistoryListPage(1)} disabled={userPaymentsHistoryPage === 0} />
        </PaginationItem>

        <PaginationItem>
          <PaginationLink previous onClick={() => this.setUserPaymentsHistoryListPage(userPaymentsHistoryPage + 1 - 1)} disabled={userPaymentsHistoryPage === 0} />
        </PaginationItem>
        
        {Array.from(Array(pageQuantity).keys())
          .map(page => Number(page) + 1)
          .map(page => (
            <PaginationItem active={userPaymentsHistoryPage + 1 === page}>
              <PaginationLink
                onClick={() => this.setUserPaymentsHistoryListPage(page)}
                disabled={userPaymentsHistoryPage + 1 === page}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
              
        <PaginationItem>
          <PaginationLink next onClick={() => this.setUserPaymentsHistoryListPage(userPaymentsHistoryPage + 1 + 1)} disabled={userPaymentsHistoryPage + 1 === pageQuantity} />
        </PaginationItem>
          
        <PaginationItem>
          <PaginationLink last onClick={() => this.setUserPaymentsHistoryListPage(pageQuantity)} disabled={userPaymentsHistoryPage + 1 === pageQuantity} />
        </PaginationItem>
      </Pagination>
    );

    return renderUserPaymentsHistoryPagination;
  };

  setUserPaymentsHistoryListPage = page => {
    const { userPaymentsHistoryList, userPaymentsHistoryPage } = this.state;

    if (page > 0) {
      const lastPage = Math.ceil(userPaymentsHistoryList.length / userPaymentsHistoryPage);

      if (page > lastPage) {
        this.setState({
          userPaymentsHistoryPage: lastPage
        });
      } else {
        this.setState({
          userPaymentsHistoryPage: page - 1
        });
      }
    }
  };

  getUserVisitsPackagesOptionsList = () => {
    const { userVisitsPackages } = this.state;
    return userVisitsPackages.map(userVisitsPackage => (
      <option key={userVisitsPackage.id} value={userVisitsPackage.id}>{userVisitsPackage.name}</option>
    ));
  };

  userVisitsPackageSelectChange = e => {
    this.setState({
      userVisitsPackageValue: e.target.value
    });
  };

  payUserVisitsPackageFormSubmit = e => {
    e.preventDefault();

    this.setState({
      userVisitsPackagePayLoading: true
    });

    adminUsersInfoBalanceAdd(this.props.match.params.userId, this.state.userVisitsPackageValue)
      .then(({ data }) => {
        this.setState({
          userVisitsPackagePayLoading: false
        });

        if (data.success) {
          const windowObject = window.open(data.data, '_blank');

          if (windowObject) {
            windowObject.focus();
          }
        } else {
          toast.error('Ошибка получения ссылки на оплату');
        }
      })
      .catch(error => {
        this.setState({
          userVisitsPackagePayLoading: false
        });

        toast.error('Ошибка получения ссылки на оплату');
      });
  };

  render () {
    const { 
      userShopSettingsForm,
      userPriceData,
      userShopYMLRequiredError,
      userShopSettingsSaveLoading, 
      updateFeedLoading,
      userShopSettingsLoading,
      resendActivationLetterLoading,
      userShopSettingsErrorLoading,
      userVisitsPackageValue, 
      userPaymentInfoErrorLoading,
      userPaymentInfoLoading,
      userVisitsBalance,
      userVisitsName,
      userPaymentsHistoryList,
      userPaymentsHistoryPerPage,
      userPaymentsHistoryErrorLoading,
      userPaymentsHistoryLoading, 
      userVisitsPackagePayLoading,
    } = this.state;

    return (
      <ContentWrapper>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-12">
              <Link to="/admin/users">
                <Button size="lg" color="primary">Назад к списку пользователей</Button>
              </Link>
              
              <h1 className="mt-4 mb-0" style={{ lineHeight: '50px' }}>Amazon (email@email.ru)</h1>
              <h3>Настройки магазина</h3>
            </div>
          </div>

          <ContentLoading
            isLoading={userShopSettingsLoading}
            isError={userShopSettingsErrorLoading}
            fetchData={() => this.getShopSettings()}
          >
            <Form name="userShopSettingsForm" onSubmit={e => this.userShopSettingsFormSubmit(e)}>
              <div className="row">
                <div className="col-xl-6">

                  <FormGroup>
                    <Label for="profileShopNameInput">Название магазна (например Амазон)</Label>
                    <Input 
                      id="profileShopNameInput" 
                      name="shopName"
                      type="text"
                      value={userShopSettingsForm.shopName}
                      onChange={this.validateOnChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="profileMainLinkInput">
                      Ссылка на основное зеркало сайта (например https://amazon.com)
                    </Label>
                    <Input 
                      id="profileMainLinkInput" 
                      name="mainDomain"
                      type="text"
                      value={userShopSettingsForm.mainDomain}
                      onChange={this.validateOnChange}
                    />
                  </FormGroup>

                  <InputGroup>
                    <Label id="profile-yml-input-label" for="profileYmlFeedLinkInput">
                      Ссылка на YML-url (например https://amazon.com/feed.yml)
                    </Label>
                    
                    <Input 
                      id="profileYmlFeedLinkInput" 
                      name="YML_URL"
                      type="text"
                      value={userShopSettingsForm.YML_URL}
                      onChange={this.validateOnChange}
                    />

                    <InputGroupAddon addonType="append">
                      <Button
                        color="primary"
                        onClick={() => this.feedUpdate()}
                        isLoading={updateFeedLoading}
                      >
                        Обновить фид
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>

                  {userShopYMLRequiredError && <p className="text-luminous-red">Поле обязательно для ввода</p>}
                  {userPriceData ? (
                    <>
                      <p className="text-spring-green">Последнее успешное обновление: {userPriceData.lastUpdate}</p>
                      <p className="text-spring-green">Всего товаров {userPriceData.itemsCount}, из них доступно к покупке {userPriceData.itemsAvaliable}.</p>
                    </>
                  ) : (
                    <p className="text-luminous-red">Фид еще не загружен</p>
                  )}

                </div>
                <div className="col-xl-6 mt-4 mt-xl-0">

                  <FormGroup>
                    <Label>Часовой пояс вашего магазина</Label>
                    <Input 
                      name="timeZone"
                      type="select" 
                      value={userShopSettingsForm.timeZone} 
                      onChange={this.validateOnChange}
                    >
                      {this.getTimeZonesOptionsList()}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="profileInput">Имя отправителя (для писем, например Магазин Амазон)</Label>
                    <Input 
                      id="profileInput" 
                      name="senderName"
                      type="text"
                      value={userShopSettingsForm.senderName}
                      onChange={this.validateOnChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="profileInput">Email отправителя (для писем, например: hello@amazon.com)</Label>                    
                    <Input 
                      id="profileInput" 
                      name="senderMail"
                      type="text"
                      value={userShopSettingsForm.senderMail}
                      onChange={this.validateOnChange}
                      invalid={this.hasError('userShopSettingsForm','senderMail','email')}
                      data-validate='["email"]'
                    />

                    {this.hasError('userShopSettingsForm','senderMail','email') && <span className="invalid-feedback">Неверно введен email</span>}
                  </FormGroup>

                  {userShopSettingsForm.mailStatus && (
                    <p>
                      Текущий статус email: &nbsp;
                      
                      {userShopSettingsForm.mailStatus === 'A' ? (
                        <span className='text-lime-green'>Активен</span>
                      ) : (
                        <>
                          <span className="text-luminous-red">Не активен</span>,
                          &nbsp; для активации перейдите по ссылке из письма сервиса. Не пришло письмо активации? Нажмите 
                          
                          {resendActivationLetterLoading ? (
                            <Spinner 
                              className="mx-2" 
                              size="sm" 
                              color="primary" 
                            />
                          ) : (
                            <>
                              <span 
                                className="mx-2 text-steel-blue link" 
                                onClick={() => this.resendActivationLetter()}
                              >
                                отправить повторно
                              </span>

                              или напишите нам по адресу help@boostmy.email
                            </>
                          )} 
                        </>
                      )}
                    </p>
                  )}

                  <Button 
                    className="mt-2"
                    color="primary" 
                    size="lg"
                    isLoading={userShopSettingsSaveLoading}
                  >
                    Сохранить
                  </Button>

                </div>
              </div>
            </Form>
          </ContentLoading>
        </div>

        <div className="info-balance-block">
          <h3>Баланс</h3>
          <ContentLoading
            isLoading={userPaymentInfoLoading}
            isError={userPaymentInfoErrorLoading}
            fetchData={() => this.getUserPaymentInfo()}
          >
            <p className="text-spring-green">{userVisitsName}: {userVisitsBalance}</p>
          </ContentLoading>
        </div>

        <div className="replenish-balance-block mt-4">
          <h3>Пополнить баланс</h3>

          <Form className="pay-visits-form" onSubmit={e => this.payUserVisitsPackageFormSubmit(e)}>
            <FormGroup>
              <Label for="visitsPackageSelect">Выберите пакет посещений</Label>
              <Input 
                id="visitsPackageSelect" 
                type="select" 
                value={userVisitsPackageValue} 
                onChange={e => this.userVisitsPackageSelectChange(e)}
              >
                {this.getUserVisitsPackagesOptionsList()}
              </Input>
            </FormGroup>

            <Button 
              className="pay-visit-btn" 
              size="lg" 
              color="primary" 
              isLoading={userVisitsPackagePayLoading}
            >
              Оплатить
            </Button>
          </Form>
        </div>

        <div className="payment-history-block mt-4">
          <h3>История покупок</h3>
          
          <ContentLoading
            isLoading={false}
            isError={userPaymentsHistoryErrorLoading}
            fetchData={() => this.getUserPaymentsHistory()}
          >
            <Table className="payment-history-table" striped responsive>
              <thead>
                <tr>
                  <th>Пакет посещений</th>
                  <th>Стоимость</th>
                  <th>Дата оплаты</th>
                </tr>
              </thead>

              <tbody>
                {userPaymentsHistoryLoading ? (
                  <tr>
                    <td colSpan="3">
                      <SpinnerLoader />
                    </td>
                  </tr>
                ) : this.getUserPaymentsHistoryList()}
              </tbody>
            </Table>

            {!userPaymentsHistoryLoading && userPaymentsHistoryList.length > userPaymentsHistoryPerPage ? (
              <div className="mt-3">
                {this.getUserPaymentsHistoryListPagination()}
              </div>
            ) : null}
          </ContentLoading>
        </div>
      </ContentWrapper>
    );
  }
}

export default AdminUserProfilePage;
