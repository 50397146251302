import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import queryString from 'query-string';
import { 
  Card,
  CardHeader,
  CardBody,
  Table,
  Input
} from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Line, Bar } from 'react-chartjs-2';
import randomColor from 'randomcolor';
import uuid from 'react-uuid';
import {
  fetchStatChartType,
  fetchStatChart,
  fetchStatData,
  fetchPaymentInfo,
  fetchSettingsIntegrationCodes,
  fetchSettingsShop,
} from 'api';

// Components
import ContentWrapper from 'components/ContentWrapper';
import ContentLoading from 'components/hoc/ContentLoading';
import SpinnerLoader from 'components/SpinnerLoader';
import Button from 'components/common/Forms/Button';

import './InformationPage.sass';

const chartOptions = {
  responsive: true,
  scales: {
    yAxes: [{
      ticks: {
        precision: 0
      }
    }]
  },
  legend: {
    position: 'bottom'
  }
};

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const WEEKDAYS_SHORT = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вск'];

class InformationPage extends Component {

  state = {
    dateFilterFrom: moment(new Date()).subtract(7, 'day'),
    dateFilterTo: moment(new Date()),
    dateInputValue: moment(new Date()).subtract(7, 'day').format('DD-MM-YYYY') + ' / ' + moment(new Date()).format('DD-MM-YYYY'),
    
    statTypeValue: 'form',
    statTypeActiveValue: null,
    
    trackCodesStatus: [],
    feedStatus: {},
    visitsBalance: null,
    visitsName: null,

    trackCodesStatusLoading: true,
    trackCodesStatusErrorLoading: true,
    feedStatusLoading: true,
    feedStatusErrorLoading: true,
    paymentInfoLoading: true,
    paymentInfoErrorLoading: true,
    statisticDataLoading: true,
    statisticDataUpdateLoading: false,
    statisticDataErrorLoading: false,
    
    audienceDynamicChartData: {},
    audienceSubscriptionsChartData: {},
    audienceTableData: {},
    formChartData: {},
    formTableData: {},
    triggersChartData: {},
    triggersTableData: {},
    
    triggerGraphType: 'opens',
    triggerGraphActiveType: null
  }

  componentDidMount () {
    this.getShopTrackStatus();
    this.getShopSettings();
    this.getPaymentInfo();
    this.graphFiltersFormSubmit();
  }

  setAnchor = () => {
    const params = queryString.parse(this.props.location.search);

    let statTypeValue = null;

    if (params.stat) {
      const statParam = params.stat;

      switch (statParam) {
        case 'trigger':
          statTypeValue = 'trigger';
          break;

        case 'form':
          statTypeValue = 'form';
          break;

        case 'audience':
          statTypeValue = 'audience';
          break;

        default:
          statTypeValue = 'form';
      }

      this.setState({
        statTypeValue
      });
    }

    return statTypeValue;
  };

  getShopTrackStatus = () => {
    this.setState({
      trackCodesStatusLoading: true,
      trackCodesStatusErrorLoading: false
    });

    fetchSettingsIntegrationCodes().then(({ data }) => {
      this.setState({ 
        trackCodesStatusLoading: false 
      });

      if (data.success) {
        this.setState({ 
          trackCodesStatus: data.data
        });
      } else {
        this.setState({
          trackCodesStatusErrorLoading: true
        });
      }
    }).catch(error => {
      this.setState({ 
        trackCodesStatusLoading: false,
        trackCodesStatusErrorLoading: true
      });
    });
  };

  getShopSettings = () => {
    this.setState({ 
      feedStatusLoading: true,
      feedStatusErrorLoading: false
    });

    fetchSettingsShop().then(({ data }) => {
      this.setState({
        feedStatusLoading: false
      });

      if (data.success) {
        this.setState({ 
          feedStatus: data.data.price
        });
      } else {
        this.setState({ 
          feedStatusErrorLoading: true
        });
      }
    }).catch(error => {
      this.setState({ 
        feedStatusLoading: false,
        feedStatusErrorLoading: true
      });
    });
  };

  getPaymentInfo = () => {
    this.setState({ 
      paymentInfoLoading: true,
      paymentInfoErrorLoading: false
    });

    fetchPaymentInfo().then(({ data }) => {
      this.setState({ 
        paymentInfoLoading: false
      });

      if (data.success) {
        this.setState({ 
          visitsBalance: data.data.billing.count,
          visitsName: data.data.billing.name
        });
      }
    }).catch(error => {
      this.setState({ 
        paymentInfoLoading: false,
        paymentInfoErrorLoading: false
      });
    });
  };

  showFromMonth = () => {
    const { dateFilterFrom, dateFilterTo } = this.state;
    
    if (!dateFilterFrom) {
      return;
    }
    
    if (moment(dateFilterTo).diff(moment(dateFilterFrom), 'months') < 2) {
      this.toDateEl.getDayPicker().showMonth(dateFilterFrom);
    }
  }

  dateFilterChange = (e, picker) => {
    e.preventDefault();
    // picker.isShowing = true;
    this.setState({
      dateFilterFrom: picker.startDate,
      dateFilterTo: picker.endDate,
      dateInputValue: moment(picker.startDate).format('DD-MM-YYYY') + ' / ' + moment(picker.endDate).format('DD-MM-YYYY'),
    })
  }

  statTypeSelectChange = e => {
    this.setState({
      statTypeValue: e.target.value
    });
  }

  audienceGraphSelectChange = e => {
    this.setState({
      triggerGraphType: e.target.value
    });
  };

  getCodeName = type => {
    let codeName = null;

    switch (type) {
      case 'base':
        codeName = 'Базовый код';
        break;

      case 'subscribe':
        codeName = 'Код подписки';
        break;

      case 'cart':
        codeName = 'Состав корзины';
        break;

      case 'order':
        codeName = 'Оформление заказа';
        break;

      case 'product_page':
        codeName = 'Просмотр товарной карточки';
        break;

      default:
    }

    return codeName;
  };

  getCodeStatusName = status => {
    let statusName = '';

    switch (status) {
      case 'A': 
        statusName = 'активен';
        break;

      case 'N': 
        statusName = 'не обнаружено';
        break;

      default:
    }

    return statusName;
  };

  getCodeStatusColor = status => {
    let codeColor = '';

    switch (status) {
      case 'A': 
        codeColor = 'success';
        break;

      case 'N': 
        codeColor = 'warning';
        break;

      default:
    }

    return codeColor;
  };

  getTrackCodesStatus = () => {
    return this.state.trackCodesStatus.map((code, i) => {
      return this.getCodeName(code.type) ? (
        <tr key={code.id}>
          <td>{this.getCodeName(code.type)}</td>
          <td>
            <Button 
              color={this.getCodeStatusColor(code.status)} 
              className="btn-oval"
            >
              {this.getCodeStatusName(code.status)}
            </Button>
          </td>
        </tr>
      ) :  null;
    }).filter(code => code);
  };

  getUnsubscriptionsName = type => {
    let unsubscriptionsName = '';

    switch (type) {
      case 'spam':
        unsubscriptionsName = 'Отписаны по жалобе на спам';
        break;

      case 'link':
        unsubscriptionsName = 'Перешли по ссылке для отписки из письма';
        break;

      case 'admin':
        unsubscriptionsName = 'Отписаны вручную в Личном кабинете';
        break;

      case 'bounce':
        unsubscriptionsName = 'Обнаружены невалидные адреса (hardbounce)';
        break;

      default:
    }

    return unsubscriptionsName;
  };

    graphFiltersFormSubmit = e => {
      if (e) e.preventDefault();

      const {
        statTypeActiveValue,
        triggerGraphType,
        triggerGraphActiveType,
        dateFilterFrom,
        dateFilterTo,
        dateFilterFromActive,
        dateFilterToActive
      } = this.state;

      let statTypeValueParam = this.setAnchor();
      let statTypeValue = statTypeValueParam ? statTypeValueParam : this.state.statTypeValue;

      if (
        statTypeValue === statTypeActiveValue && 
        triggerGraphType === triggerGraphActiveType && 
        dateFilterFrom === dateFilterFromActive &&
        dateFilterTo === dateFilterToActive
      ) {
        return null;
      }

      this.setState({
        statisticDataUpdateLoading: true,
        statisticDataErrorLoading: false
      });

      let requestApi = '';
      const requestParams = {
        'from': moment(dateFilterFrom).format('YYYY-MM-DD'),
        'to': moment(dateFilterTo).format('YYYY-MM-DD'),
      };

      if (statTypeValue === 'trigger') {
        requestApi = fetchStatChartType(statTypeValue, triggerGraphType, requestParams);
      } else {
        requestApi = fetchStatChart(statTypeValue, requestParams);
      }

      requestApi
        .then(responseGraph => {
          fetchStatData(statTypeValue, requestParams)
            .then(responseTable => {
              this.setState({
                statisticDataLoading: false,
                statisticDataUpdateLoading: false
              });
              
              if (responseGraph.data.success && responseTable.data.success) {
                switch (statTypeValue) {
                  case 'form':
                    const {
                      formChartData: formChartDataCurrent
                    } = this.state;

                    let formChartData = {};

                    formChartData.labels = Object.keys(responseGraph.data.data.views);

                      formChartData.datasets = [
                        {
                          label: 'Показы',
                          borderColor: randomColor(),
                          data: Object.values(responseGraph.data.data.views)
                        },
                        {
                          label: 'Подписки',
                          borderColor: randomColor(),
                          data: Object.values(responseGraph.data.data.subscribes)
                        }
                      ];

                      if (formChartDataCurrent.datasets) {
                        formChartData.datasets = formChartData.datasets.map((dataset, i) => ({
                          ...dataset,
                          borderColor: formChartDataCurrent.datasets[i].borderColor
                        }));
                      }

                      const formTableData = responseTable.data.data;
                      
                      this.setState({
                        formChartData,
                        formTableData,
                        statTypeActiveValue: statTypeValue,
                        triggerGraphActiveType: triggerGraphType,
                        dateFilterFromActive: dateFilterFrom,
                        dateFilterToActive: dateFilterTo
                      });

                      break;

                    case 'audience':
                      const { 
                        audienceDynamicChartData: audienceDynamicChartDataCurrent,
                        audienceSubscriptionsChartData: audienceSubscriptionsChartDataCurrent
                      } = this.state;

                      let audienceDynamicChartData = {};
                      let audienceSubscriptionsChartData = {};

                      audienceDynamicChartData.labels = Object.keys(responseGraph.data.data.dynamic);
                      audienceDynamicChartData.datasets = [
                        {
                          label: 'Динамика подписчиков',
                          borderColor: randomColor(),
                          data: Object.values(responseGraph.data.data.dynamic)
                        }
                      ];

                      if (audienceDynamicChartDataCurrent.datasets) {
                        audienceDynamicChartData.datasets = audienceDynamicChartData.datasets.map((dataset, i) => ({
                          ...dataset,
                          borderColor: audienceDynamicChartDataCurrent.datasets[i].borderColor
                        }));
                      }

                      audienceSubscriptionsChartData.labels = Object.keys(responseGraph.data.data.activity.subscribtions);
                      audienceSubscriptionsChartData.datasets = [
                        {
                          id: uuid(),
                          label: 'Добавлены трек-кодом размещенным на сайте',
                          backgroundColor: randomColor(),
                          data: Object.values(responseGraph.data.data.activity.subscribtions)
                        },
                        {
                          id: uuid(),
                          label: this.getUnsubscriptionsName('spam'),
                          backgroundColor: randomColor(),
                          data: Object.values(responseGraph.data.data.activity.unsubscribtions.spam)
                        },
                        {
                          id: uuid(),
                          label: this.getUnsubscriptionsName('link'),
                          backgroundColor: randomColor(),
                          data: Object.values(responseGraph.data.data.activity.unsubscribtions.link)
                        },
                        {
                          id: uuid(),
                          label: this.getUnsubscriptionsName('admin'),
                          backgroundColor: randomColor(),
                          data: Object.values(responseGraph.data.data.activity.unsubscribtions.admin)
                        },
                        {
                          id: uuid(),
                          label: this.getUnsubscriptionsName('bounce'),
                          backgroundColor: randomColor(),
                          data: Object.values(responseGraph.data.data.activity.unsubscribtions.bounce)
                        }
                      ];

                      if (audienceSubscriptionsChartDataCurrent.datasets) {
                        audienceSubscriptionsChartData.datasets = audienceSubscriptionsChartData.datasets.map((dataset, i) => ({
                          ...dataset,
                          backgroundColor: audienceSubscriptionsChartDataCurrent.datasets[i].backgroundColor
                        }));
                      }

                      const audienceTableData = responseTable.data.data;
                      
                      this.setState({
                        audienceDynamicChartData,
                        audienceSubscriptionsChartData,
                        audienceTableData,
                        statTypeActiveValue: statTypeValue,
                        triggerGraphActiveType: triggerGraphType,
                        dateFilterFromActive: dateFilterFrom,
                        dateFilterToActive: dateFilterTo
                      });

                      break;

                    case 'trigger':
                      const {
                        triggersChartData: triggersChartDataCurrent
                      } = this.state;

                      let triggersChartData = {};
                      triggersChartData.labels = Object.keys(responseGraph.data.data[0].chart);
                      triggersChartData.datasets = responseGraph.data.data.map(trigger => ({
                        label: trigger.name,
                        borderColor: randomColor(),
                        data: Object.values(trigger.chart)
                      }));

                      if (triggersChartDataCurrent.datasets) {
                        triggersChartData.datasets = triggersChartData.datasets.map((dataset, i) => ({
                          ...dataset,
                          borderColor: triggersChartDataCurrent.datasets[i].borderColor
                        }));
                      }

                      const triggersTableData = responseTable.data.data;
                      
                      this.setState({
                        triggersChartData,
                        triggersTableData,
                        statTypeActiveValue: statTypeValue,
                        triggerGraphActiveType: triggerGraphType,
                        dateFilterFromActive: dateFilterFrom,
                        dateFilterToActive: dateFilterTo
                      });

                      break;

                    default:
                }
              } else {
                this.setState({
                  statisticDataErrorLoading: true
                });
              }

              if (statTypeValueParam) {
                document.getElementById('statistics').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
              }
            })
            .catch(error => {
              this.setState({
                statisticDataErrorLoading: true,
                statisticDataLoading: false,
                statisticDataUpdateLoading: false
              });

              if (statTypeValueParam) {
                document.getElementById('statistics').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
              }
            });
        })
        .catch(error => {
          this.setState({
            statisticDataErrorLoading: true,
            statisticDataLoading: false,
            statisticDataUpdateLoading: false
          });
        });
    }

    reduceArrToSum = arr => {
      return arr.reduce((valPrev, valNext) => Number(valPrev) + Number(valNext));
    };

    getTriggersChart = () => {
      const { triggersChartData, triggersTableData } = this.state;

      return (
        <>
          <Card className="card-default mt-4">
            <CardBody>
              <Line 
                data={triggersChartData} 
                options={{ ...chartOptions}} 
                height={120} 
              />
            </CardBody>
          </Card>

          <Card className="card-default mb-5">
            <CardBody>
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>Название триггера</th>
                    <th>Писем отправлено</th>
                    <th>Открыто</th>
                    <th>Уник.откр.</th>
                    <th>Кликов</th>
                    <th>Уник. кл.</th>
                    <th>Жалоб</th>
                    <th>Заказов</th>
                    <th>Выручка</th>
                  </tr>
                </thead>

                <tbody>
                  {triggersTableData.map((tableData, i) => (
                    <tr key={tableData.id}>
                      <td>{tableData.name}</td>
                      <td>{tableData.sended}</td>
                      <td>{tableData.opens}</td>
                      <td>{tableData.uniqueOpens}</td>
                      <td>{tableData.clicks}</td>
                      <td>{tableData.uniqueClicks}</td>
                      <td>{tableData.complaints}</td>
                      <td>{tableData.orders}</td>
                      <td>{tableData.proceeds}</td>
                    </tr>
                  ))}

                  <tr>
                    <td>Итого</td>
                    <td>{this.reduceArrToSum(triggersTableData.map(tableData => tableData.sended))}</td>
                    <td>{this.reduceArrToSum(triggersTableData.map(tableData => tableData.opens))}</td>
                    <td>{this.reduceArrToSum(triggersTableData.map(tableData => tableData.uniqueOpens))}</td>
                    <td>{this.reduceArrToSum(triggersTableData.map(tableData => tableData.clicks))}</td>
                    <td>{this.reduceArrToSum(triggersTableData.map(tableData => tableData.uniqueClicks))}</td>
                    <td>{this.reduceArrToSum(triggersTableData.map(tableData => tableData.complaints))}</td>
                    <td>{this.reduceArrToSum(triggersTableData.map(tableData => tableData.orders))}</td>
                    <td>{this.reduceArrToSum(triggersTableData.map(tableData => tableData.proceeds))}</td>
                  </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </>
      );
    };

    getFormsChart = () => {
      const { formChartData, formTableData } = this.state;
      let totalConversion = null;
      const totalShowed = this.reduceArrToSum(formTableData.map(tableData => tableData.showed));
      const totalSubscribed = this.reduceArrToSum(formTableData.map(tableData => tableData.subscribed));

      if (totalShowed === 0 && totalSubscribed === 0) {
        totalConversion = 0;
      } else if (totalSubscribed > totalShowed) {
        totalConversion = 100;
      } else {
        totalConversion = +(Math.round(totalSubscribed / totalShowed * 100 + "e+1")  + "e-1");
      }

      return (
        <>
          <Card className="card-default mt-4">
            <CardBody>
              <Line data={formChartData} options={chartOptions} height={120} />
            </CardBody>
          </Card>

           <Card className="card-default mt-5">
            <CardBody>
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>Название формы</th>
                    <th>Показов</th>
                    <th>Подписалось</th>
                    <th>Конверсия</th>
                  </tr>
                </thead>

                <tbody>
                  {formTableData.map(tableData => (
                    <tr key={tableData.id}>
                      <td>{tableData.name}</td>
                      <td>{tableData.showed}</td>
                      <td>{tableData.subscribed}</td>
                      <td>{tableData.conversion}%</td>
                    </tr>
                  ))}

                  <tr>
                    <td>Итого</td>
                    <td>{totalShowed}</td>
                    <td>{totalSubscribed}</td>
                    <td>{totalConversion} %</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
      </>
      );
    };

    getAudienceChart = () => {
      const { 
        audienceDynamicChartData, 
        audienceSubscriptionsChartData
      } = this.state;

      const subscribesNumStart = audienceDynamicChartData.datasets[0].data[0];
      const subscribesNumEnd = audienceDynamicChartData.datasets[0].data[audienceDynamicChartData.datasets[0].data.length - 1];
      const subscribersDifference = subscribesNumEnd - subscribesNumStart;
      let subscribersDifferencePercent = null;

      if (subscribersDifference === 0) {
        subscribersDifferencePercent = 0;
      } else if (subscribesNumStart === 0 && subscribersDifference > 0) {
        subscribersDifferencePercent = 100;
      } else {
        subscribersDifferencePercent = Math.round(subscribersDifference / subscribesNumStart * 1000) / 10;
      }

      return (
        <>
          <Card className="card-default mt-4">
            <CardHeader>Динамика подписчиков</CardHeader>
            <CardBody>
              <Line data={audienceDynamicChartData} options={chartOptions} height={120} />
            </CardBody>
          </Card>

          <Card className="card-default mt-4">
            <CardHeader>Подписки и отписки</CardHeader>
            <CardBody>
              <Bar data={audienceSubscriptionsChartData} options={chartOptions} height={120} />
              <Table bordered hover responsive className="mt-4">
                <thead>
                  <tr>
                    <th>Количество подписчиков на начало периода</th>
                    <th>Количество подписчиков на конец периода</th>
                    <th>Изменение за весь период</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{subscribesNumStart}</td>
                    <td>{subscribesNumEnd}</td>
                    <td>
                      <span className={`${subscribersDifference > 0 ? 'text-spring-green' : 'text-luminous-red'}`}>
                        {`${subscribersDifference > 0 ? '+' : ''}${subscribersDifference} / ${subscribersDifferencePercent}%`}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <Card className="card-default mt-5">
            <CardBody>
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>Источник</th>
                    <th>Кол-во E-mail</th>
                  </tr>
                </thead>
                <tbody>
                  {audienceSubscriptionsChartData.datasets.map(chartData => (
                    <tr key={chartData.id}>
                      <td>
                        <span 
                          className="node-legend" 
                          style={{ backgroundColor: chartData.backgroundColor }}>
                        </span>

                        {chartData.label}
                      </td>
                      
                      <td>{this.reduceArrToSum(chartData.data)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>Всего отписалось:</td>
                    <td>{this.reduceArrToSum(audienceSubscriptionsChartData.datasets.slice(1).map(chartData => chartData.data).flat())}</td>
                  </tr>
                  <tr>
                    <td>Всего подписалось:</td>
                    <td>{this.reduceArrToSum(audienceSubscriptionsChartData.datasets.slice(0, 1).map(chartData => chartData.data).flat())}</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </>
      );
    };

    getCharts = () => {
      const { statTypeActiveValue } = this.state;
      let chartDataElements = null;

      switch (statTypeActiveValue) {
        case 'audience':
          chartDataElements = this.getAudienceChart();
            break;

          case 'form':
            chartDataElements = this.getFormsChart();
          break;

        case 'trigger':
          chartDataElements = this.getTriggersChart();
          break;

        default:
      }    

      return chartDataElements;   
    };

  render () {
    const {
      dateFilterFrom,
      dateFilterTo,
      dateInputValue,
      feedStatus,
      feedStatusLoading,
      trackCodesStatusLoading,
      statTypeValue,
      visitsName,
      visitsBalance,
      paymentInfoLoading,
      statisticDataLoading,
      statisticDataUpdateLoading,
      trackCodesStatusErrorLoading,
      feedStatusErrorLoading,
      paymentInfoErrorLoading,
      statisticDataErrorLoading
    } = this.state;

    return (
      <ContentWrapper>
        <section className="information-page-sect">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6">

                <h1 className="mb-0">Информация</h1>

                <div className="info-balance-block">
                  <h3>Баланс</h3>

                  <ContentLoading
                    isLoading={paymentInfoLoading}
                    isError={paymentInfoErrorLoading}
                    fetchData={() => this.getPaymentInfo()}
                  >
                    <p className="text-spring-green">{visitsName}: {visitsBalance}</p>
                    <Link to="/payment">Перейти к пополнению баланса</Link>
                  </ContentLoading>
                </div>

                <div className="info-track-code-status-table-block mt-4">
                  <h3>Статус установки трек-кодов <Link className="link ml-2" to="/profile/settings/integration">Перейти к интеграции</Link></h3>

                  <ContentLoading
                    isLoading={false}
                    isError={trackCodesStatusErrorLoading}
                    fetchData={() => this.getShopTrackStatus()}
                  >
                    <Card className="card-default">
                      <CardBody>
                        <Table bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Трек-код</th>
                              <th>Статус</th>
                            </tr>
                          </thead>

                          <tbody>
                            {trackCodesStatusLoading ? (
                              <tr><td colSpan="2">
                                <SpinnerLoader />
                              </td></tr>
                            ) : this.getTrackCodesStatus()}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </ContentLoading>
                </div>

                <div className="info-product-feed-load-status-block mt-4">
                  <h3>Статус загрузки товарного фида (YML)</h3>

                  <div id="statistics"></div>

                  <ContentLoading
                    isLoading={feedStatusLoading}
                    isError={feedStatusErrorLoading}
                    fetchData={() => this.getShopSettings()}
                  >
                    <p className={`${feedStatus ? 'text-spring-green' : 'text-luminous-red'}`}>
                      {feedStatus ? `Фид успешно загружен ${feedStatus.lastUpdate}` : 'Фид еще не был загружен'}
                      <br/>
                      {feedStatus && `Обнаружено ${feedStatus.itemsCount} товаров, из них ${feedStatus.itemsAvaliable} доступно к покупке`}
                    </p>
                  </ContentLoading>
                </div>

              </div>
              <div className="col-12">
              
                <div className="info-period-statistics mt-4">
                  <h3>Статистика за период</h3>

                  <form onSubmit={this.graphFiltersFormSubmit}>
                    <div className="graph-filter-controls">
                      <DateRangePicker
                        style={{ display: 'block' }}
                        startDate={dateFilterFrom}
                        endDate={dateFilterTo}
                        onEvent={this.dateFilterChange}
                        alwaysShowCalendars={true}
                        autoclose={false}
                        autoApply={true}
                        closeOnDateSelect={false}
                        locale={{
                          daysOfWeek: WEEKDAYS_SHORT,
                          monthNames: MONTHS,
                          applyLabel: 'Применить',
                          cancelLabel: 'Сброс',
                          customRangeLabel: "Своя дата"
                        }}
                        ranges={{
                          'Сегодня': [moment(new Date()), moment(new Date())],
                          'Вчера': [moment(new Date()).subtract(1, 'days'), moment(new Date()).subtract(1, 'days')],
                          'Последняя неделя': [moment(new Date()).subtract(6, 'days'), moment()],                   
                          'Этот месяц': [moment(new Date()).startOf('month'), moment(new Date()).endOf('month')]                   
                        }}
                      >
                        <span className="form-control mb-3 ml-xs-2">
                          {dateInputValue}
                        </span>
                      </DateRangePicker>

                      <Input 
                        className="stat-for-period-select ml-xs-4 mb-4" 
                        type="select" 
                        name="select" 
                        value={statTypeValue}
                        onChange={this.statTypeSelectChange}
                      >
                        <option value="trigger">Сценарии</option>
                        <option value="form">Формы</option>
                        <option value="audience">Аудитория</option>
                      </Input>

                      {statTypeValue === 'trigger' && (
                        <Input 
                          className="audience-graph-select ml-xs-4 mb-4" 
                          type="select" 
                          name="select" 
                          onChange={this.audienceGraphSelectChange}
                        >
                          <option value="opens">Открыто</option>
                          <option value="unique_opens">Уник. открытий</option>
                          <option value="clicks">Кликов</option>
                          <option value="unique_clicks">Уник. кликов</option>
                          <option value="complaints">Жалоб</option>
                          <option value="orders">Заказов</option>
                          <option value="proceeds">Выручка</option>
                        </Input>
                      )}

                      <Button 
                        className="ml-4" 
                        color="primary" 
                        size="lg"
                        disabled={!statisticDataLoading && statisticDataUpdateLoading}
                      >
                        Показать
                      </Button>
                    </div>
                  </form>

                  <ContentLoading
                    isLoading={statisticDataLoading}
                    isError={statisticDataErrorLoading}
                    fetchData={() => this.graphFiltersFormSubmit()}
                  >
                    {this.getCharts()}
                  </ContentLoading>
                </div>

              </div>
            </div>
          </div>
        </section>
      </ContentWrapper>
    );
  }
}

export default InformationPage;
