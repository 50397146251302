import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Button,
  Modal,
  ModalBody,
  Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { faExpeditedssl } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import {
  fetchShopUserTimeline,
  shopUserDelete,
  shopDeleteEmail,
  shopBlacklistEmailAdd,
} from 'api';

// Components
import ContentWrapper from 'components/ContentWrapper';
import ContentLoading from 'components/hoc/ContentLoading';
import SpinnerLoader from 'components/SpinnerLoader';

import './SubscriberProfilePage.sass';

class SubscriberProfilePage extends Component {

  state = {
    profileEmailsList: [],
    profileEmailsLoading: false,
    profileEmailsErrorLoading: false,
    profileTimeline: [],
    deleteEmailModalOpen: false,
    addEmailToExceptionModalOpen: false,
    deleteProfileModalOpen: false,
    emailToDelete: null,
    emailToException: null
  };

  componentDidMount () {
    this.getProfileEmails();
  }

  getProfileEmails = () => {
    this.setState({
      profileEmailsLoading: true,
      profileEmailsErrorLoading: false
    });

    fetchShopUserTimeline(this.props.match.params.profileId)
      .then(({ data }) => {
        this.setState({
          profileEmailsLoading: false
        });

        if (data.success) {
          this.setState({
            profileEmailsList: data.data.emails,
            profileTimeline: data.data.timeline.map(timeline => ({
              ...timeline,
              id: uuid()
            }))
          });
        } else {
          this.setState({
            profileEmailsErrorLoading: true
          });
        }
      }).catch(error => {
        this.setState({
          profileEmailsErrorLoading: true,
          profileEmailsLoading: false
        });
      });
  };

  getProfileEmailsList = () => {
    let renderProfileEmails = null;

    renderProfileEmails = this.state.profileEmailsList.map(email => (
      <tr key={email.id}>
        <td>{email.email}</td>
        <td>{email.sourceID}</td>
        <td>{email.subscribeDate}</td>

        <td className="text-right">
          <ul className="profile-subscribers-controls-list">
            <li 
              className="control-item" 
              onClick={() => this.triggerModalEmailException(email.id)}
            >
              <FontAwesomeIcon title="Добавить в список исключений" icon={faExpeditedssl} />
            </li>

            <li 
              className="control-item" 
              onClick={() => this.triggerModalEmailDelete(email.id)}
            >
              <FontAwesomeIcon title="Удалить пользователя" icon={faTrashAlt} />
            </li>
          </ul>
        </td>
      </tr>
    ));

    return renderProfileEmails;
  };

  getProfileTimeline = () => {
    const { profileTimeline } = this.state;

    return (
      <div className="timeline-wrapper mt-5">
        <div className="first-column">
          <ul className="timeline-list">
            {profileTimeline.map(timeline => (
              <li key={timeline.id}>{timeline.date}</li>
            ))}
          </ul>
        </div>

        <div className="second-column">
          <ul className="timeline-list">
            {profileTimeline.map(timeline => (
              <li key={timeline.id}>{timeline.info}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  deleteProfile = () => {
    const { match, history } = this.props;

    shopUserDelete(match.params.uuid)
      .then(({ data }) => {
        if (data.success) {
          toast.success('Профиль успешно удален');  

          history.push('/audience');
        } else {
          toast.error('Ошибка при удалении пользователя');
        }
      })
      .catch(error => {
        toast.error('Ошибка при удалении пользователя');
      });
  };

  deleteEmail = id => {
    const { profileEmailsList, deleteEmailModalOpen } = this.state;

    shopDeleteEmail(id)
      .then(({ data }) => {
        if (data.success) {
          let profileEmailsListTemp = profileEmailsList;

          const deleteIndex = profileEmailsListTemp.findIndex(email => email.id === id);

          profileEmailsListTemp.splice(deleteIndex, 1);

          this.setState({
            profileEmailsList: profileEmailsListTemp,
            deleteEmailModalOpen: !deleteEmailModalOpen
          });

          toast.success('Почтовый адрес успешно удален.');
        } else {
          toast.error('Ошибка при удалении почтового адреса');
        }
      })
      .catch(error => {
        toast.error('Ошибка при удалении почтового адреса');
      });
  };

  addEmailToException = id => {
    const { profileEmailsList, addEmailToExceptionModalOpen } = this.state;

    shopBlacklistEmailAdd(id)
      .then(({ data }) => {
        if (data.success) {
          let profileEmailsListTemp = profileEmailsList;

          const addToExceptionIndex = profileEmailsListTemp.findIndex(email => email.id === id);

          profileEmailsListTemp.splice(addToExceptionIndex, 1);

          this.setState({
            profileEmailsList: profileEmailsListTemp,
            addEmailToExceptionModalOpen: !addEmailToExceptionModalOpen
          });

          toast.success('Почтовый адрес успешно добавлен в список исключений');
        } else {
          toast.error('Ошибка при добавлении почтового адреса в список исключений');
        }
      })
      .catch(error => {
        toast.error('Ошибка при добавлении почтового адреса в список исключений');
      });
  };

  triggerModalEmailDelete = id => {
    this.setState({
      deleteEmailModalOpen: !this.state.deleteEmailModalOpen,
      emailToDelete: id
    });
  };

  triggerModalEmailException = id => {
    this.setState({
      addEmailToExceptionModalOpen: !this.state.addEmailToExceptionModalOpen,
      emailToException: id
    });
  };

  render () {
    const {
      deleteEmailModalOpen,
      addEmailToExceptionModalOpen,
      deleteProfileModalOpen,
      profileEmailsLoading,
      profileEmailsErrorLoading,
      emailToDelete,
      emailToException,
    } = this.state;

    return (
      <ContentWrapper>
        <ContentLoading
          isLoading={false}
          isError={profileEmailsErrorLoading}
          fetchData={() => this.getProfileEmails()}
        >
          <Card>
            <Modal
              isOpen={deleteEmailModalOpen}
              toggle={() => this.setState({ deleteEmailModalOpen: !deleteEmailModalOpen })}
            >
              <ModalBody className="text-center">
                <h3>Удалить выбранный адрес?</h3>
                <p>Выбранный адрес будет удален. Отменить данное действие невозможно.</p>

                <div>
                  <Button
                    color="primary"
                    onClick={() => this.deleteEmail(emailToDelete)}
                  >
                    Удалить
                  </Button>
                  
                  <Button
                    color="secondary"
                    className="ml-2"
                    onClick={() => this.setState({ deleteEmailModalOpen: !deleteEmailModalOpen })}
                  >
                    Отмена
                  </Button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={addEmailToExceptionModalOpen}
              toggle={() => this.setState({ addEmailToExceptionModalOpen: !addEmailToExceptionModalOpen })}
            >
              <ModalBody className="text-center">
                <h3>Добавить в список исключений?</h3>

                <p>Выбранный адрес будет помещен в список исключений. Добавленный Вами адрес можно восстановить вручную в любой момент времени.</p>

                <div>
                  <Button
                    color="primary"
                    onClick={() => this.addEmailToException(emailToException)}
                  >
                    Добавить в исключение
                  </Button>
                  
                  <Button
                    color="secondary"
                    className="ml-2"
                    onClick={() => this.setState({ addEmailToExceptionModalOpen: !addEmailToExceptionModalOpen })}
                  >
                    Отмена
                  </Button>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={deleteProfileModalOpen}
              toggle={() => this.setState({ deleteProfileModalOpen: !deleteProfileModalOpen })}
            >
              <ModalBody className="text-center">
                <h3>Удалить все сведения о пользователя?</h3>

                <p>Все данные пользователя будут удалены безвозвратно. Отменить данное действие невозможно.</p>

                <FormGroup>
                  <label className="c-checkbox">
                    <input type="checkbox" />
                    <FontAwesomeIcon icon={faCheck} /> Добавить почтовые адреса в список исключений
                  </label>
                </FormGroup>

                <div className="mt-3">
                  <Button
                    color="primary"
                    onClick={() => this.deleteProfile()}
                  >
                    Удалить
                  </Button>
                  
                  <Button
                    color="secondary"
                    className="ml-2"
                    onClick={() => this.setState({ deleteProfileModalOpen: !deleteProfileModalOpen })}
                  >
                    Отмена
                  </Button>
                </div>
              </ModalBody>
            </Modal>

            <CardHeader className="subscriber-profile-header">
              <div className="profile-header">
                <h5>UUID</h5>
                <h3>{this.props.match.params.uuid}</h3>
              </div>

              <div className="header-btns-control">
                <Link to="/audience/exceptions">
                  <Button color="primary" size="lg">В список исключений</Button>
                </Link>
                
                <Button
                  className="ml-3"
                  onClick={() => this.setState({ deleteProfileModalOpen: !deleteProfileModalOpen })}
                  color="success" size="lg"
                >
                  Удалить пользователя
                </Button>
              </div>
            </CardHeader>

            <CardBody>
              <Table className="profile-subscribers-list-table" striped responsive>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Источник исключения (id)</th>
                    <th>Дата подписки</th>
                  </tr>
                </thead>

                <tbody>
                  {profileEmailsLoading ? (
                    <tr>
                      <td colspan="3">
                        <SpinnerLoader />
                      </td>
                    </tr>
                  ) : this.getProfileEmailsList()}
                </tbody>
              </Table>
            </CardBody>
          </Card>

          {this.getProfileTimeline()}
        </ContentLoading>       
      </ContentWrapper>
    );
  }
}

export default SubscriberProfilePage;
