import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Progress,
  FormGroup,
  Input,
  Button,
  Label
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import JSONPretty from 'react-json-pretty';
import { toast } from 'react-toastify';
import {
  fetchSettingsIntegrationCodes,
} from 'api';

// Components
import ContentWrapper from 'components/ContentWrapper';
import ContentLoading from 'components/hoc/ContentLoading';

import './ProfileIntegrationSettings.sass';

class ProfileIntegrationSettings extends Component {

  state = {
    shopIntegrationCodes: [],
    shopIntegrationLoading: true,
    shopIntegrationErrorLoading: false
  };

  componentDidMount () {
    this.getShopIntegrationInfo();
  }

  getShopIntegrationInfo = () => {
    this.setState({
      shopIntegrationLoading: true
    });

    fetchSettingsIntegrationCodes()
      .then(({ data }) => {
        if (data.success) {
          this.setState({
            shopIntegrationLoading: false,
            shopIntegrationCodes: data.data
          });
        } else {
          this.setState({
            shopIntegrationLoading: false,
            shopIntegrationErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          shopIntegrationLoading: false,
          shopIntegrationErrorLoading: true
        });
      });
  };

  getIntegrationProgress = () => {
    const codes = ['base', 'subscribe', 'cart', 'order', 'order_hook', 'product_page'];
    const numSuccessCodes = codes.map(code => this.getCode(code).status).filter(code => code === 'A').length;
    return Math.round(100 / codes.length * numSuccessCodes);
  };

  getStatusText = status => {
    let statusElement = '';

    switch (status) {
      case 'A':
        statusElement = <p className="text-spring-green">Активен</p>
        break;

      case 'N':
        statusElement = <p className="text-luminous-red">Данные не поступают</p>
        break;

      default:
    }

    return statusElement;
  };

  getCode = type => {
    const { shopIntegrationCodes } = this.state;
    let code = null;

    switch (type) {
      case 'base':
        code = shopIntegrationCodes.find(code => code.type === type);
        break;

      case 'subscribe':
        code = shopIntegrationCodes.find(code => code.type === type);
        break;

      case 'cart':
        code = shopIntegrationCodes.find(code => code.type === type);
        break;

      case 'order':
        code = shopIntegrationCodes.find(code => code.type === type);
        break;

      case 'order_hook':
        code = shopIntegrationCodes.find(code => code.type === type);
        break;

      case 'product_page':
        code = shopIntegrationCodes.find(code => code.type === type);
        break;

      default:
    }

    return code;
  };

  pinCopiedAlert = () => {
      toast.success('Скопировано', { autoClose: 1500 });
    };

  render () {
    const {
      shopIntegrationLoading,
      shopIntegrationErrorLoading,
    } = this.state;

    return (
      <ContentWrapper>
        <ContentLoading
          isLoading={shopIntegrationLoading}
          isError={shopIntegrationErrorLoading}
          fetchData={() => this.getShopIntegrationInfo()}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4">

                <h2>Интеграция</h2>

              </div>
              <div className="col-xl-8">

                <div className="mb-2 text-center">Прогресс интеграции</div>
                <Progress className="progress" value={this.getIntegrationProgress()}>{this.getIntegrationProgress()}%</Progress>

              </div>
              <div className="col-12">

                <p>В данном разделе приведены код отслеживания событий сата, а также даны инструкции по проведению интеграции сервиса с вашим магазином. Если у вас нет технического специалиста, который мог бы выполнить указанные действия, вы всегда можете обратиться за помощью к нам, написав письмо на почту <a href="mailto:help@boostmy.email">help@boostmy.email</a> или заполнив <span className="text-steel-blue">форму связи</span></p>
                <p>Обратите внимание, под каждым блоком кода указан его текущий статус. После успешной установки, как только сервис начнет получать соответствущие данные, статус кода будет меняться на "Установлен, идет приём данных". Для использования всех доступных инструментов сервиса вам потребуется установить все указанные коды.</p>
                <p>Прежде всего нужно установить базовый код, он должен присутствовать на каждой странице вашего магазина. Разместите указанный код в самом конце страницы перед закрывающим тегом &lt;/body&gt;</p>

                <FormGroup>
                  <Label for="baseCodeInput">Базовый код</Label>
                  <Input 
                    id="baseCodeInput"
                    type="textarea"
                    rows="7"
                    value={this.getCode('base').code}
                    disabled
                  />
                  <CopyToClipboard 
                    text={this.getCode('base').code}
                    onCopy={() => this.pinCopiedAlert()}
                  >
                    <Button className="mt-2" color="primary">Скопировать код</Button>
                  </CopyToClipboard>
                </FormGroup>

                {this.getStatusText(this.getCode('base').status)}

                <p>Сервис автоматически создал для вашего магазина приветственный триггер (welcome - триггер, отправляется при подписке пользователя, например при регистрации в вашем магазине или при подписке через форму), для начала сбора почтовых адресов ваших клиентов вам нужно создать форму подписки, либо установить на сайт приведенный пользователей о том, что подписываете их на рассылку - таково требование законов РФ.</p>
                <p>В приведенной ниже JS-код подписки необходимо делать подстановку email-адреса, вместо #user_email#</p>

                <FormGroup>
                  <Label for="subscribeCodeInput">Код подписки</Label>
                  <Input 
                    id="subscribeCodeInput"
                    type="textarea"
                    value={this.getCode('subscribe').code}
                    disabled
                  />
                  <CopyToClipboard 
                    text={this.getCode('subscribe').code}
                    onCopy={() => this.pinCopiedAlert()}
                  >
                    <Button className="mt-2" color="primary">Скопировать код</Button>
                  </CopyToClipboard>
                </FormGroup>

                {this.getStatusText(this.getCode('subscribe').status)}

                <hr />

                <p>Следующий код выполняется каждый раз, когда пользователь вашего магазина изменяет состав корзины (добавляет/убирает товары, очищает корзину). Этот код необходим для работы триггеров типа "брошеная корзина". Помнит5, что для работы таких триггеров обязательно наличие корректног YML-фида, указанного в <Link to="/profile/settings/shop">настройках магазина</Link>.</p>
                <p>В приведенный ниже JS-код подписки необходимо делать подстановку данных о составе корзины, вместо #cart_json#, в следующем формате:</p>

                <JSONPretty 
                  data={[
                    {
                      "id": 204,
                      "quantity": 2,
                      "price": 2699
                    },
                    {
                      "id": 144,
                      "quantity": 1,
                      "price": 1199
                    }
                  ]} 
                />

                <FormGroup>
                  <Label for="cartUpdateCodeInput">Код обновления состава корзины</Label>
                  <Input 
                    id="cartUpdateCodeInput"
                    type="textarea"
                    value={this.getCode('cart').code}
                    disabled
                  />

                  <CopyToClipboard 
                    text={this.getCode('cart').code}
                    onCopy={() => this.pinCopiedAlert()}
                  >
                    <Button className="mt-2" color="primary">Скопировать код</Button>
                  </CopyToClipboard>
                </FormGroup>

                {this.getStatusText(this.getCode('cart').status)}

                <hr />

                <p>Следующий код должен выполняться в случае успешного оформления заказа. Этот код необходим для работы триггеров типа "купленная корзина" (спасибо-триггер). <br />Вомните, что для работы таких триггеров обязательно наличие корректного YML-фида, указанного в <Link to="/profile/settings/shop">настройках магазина</Link>.</p>
                <p>В приведенный ниже JS-код подписке необходимо делать подстановку данных о составе корзины, вместо #order_info#, в следующем формате:</p>

                <JSONPretty 
                  data={{
                    "id": 1,
                    "delivery_price": 500,
                    "cart": [
                      {
                        "id": 204,
                        "quantity": 2,
                        "price": 2699
                      },
                      {
                        "id": 144,
                        "quantity": 1,
                        "price": 1199
                      }
                    ]
                  }} 
                />

                <FormGroup>
                  <Label for="orderIssueCodeInput">Код оформления заказа</Label>
                  <Input 
                    id="orderIssueCodeInput"
                    type="textarea"
                    value={this.getCode('order').code}
                    disabled
                  />
                  <CopyToClipboard 
                    text={this.getCode('order').code}
                    onCopy={() => this.pinCopiedAlert()}
                  >
                    <Button className="mt-2" color="primary">Скопировать код</Button>
                  </CopyToClipboard>
                </FormGroup>

                {this.getStatusText(this.getCode('order').status)}
                <p>Для более точного отслеживания эффективности инструментов сервиса необходимо отправлять информацию об изменении статуса заказа на webhook URL. <br />Данные должны передаваться в параметр "data", в формате json:</p>

                <JSONPretty 
                  data={{
                    "id": 1,
                    "status": 'C',
                    "delivery_price": 500,
                    "cart": [
                      {
                        "id": 204,
                        "quantity": 2,
                        "price": 2699
                      },
                      {
                        "id": 144,
                        "quantity": 1,
                        "price": 1199
                      }
                    ]
                  }} 
                />

                <FormGroup>
                  <Label for="webhookURLInput">Webhook URL</Label>
                  <Input 
                    id="webhookURLInput"
                    type="textarea"
                    value={this.getCode('order_hook').code}
                    disabled
                  />
                  <CopyToClipboard 
                    text={this.getCode('order_hook').code}
                    onCopy={() => this.pinCopiedAlert()}
                  >
                    <Button className="mt-2" color="primary">Скопировать код</Button>
                  </CopyToClipboard>
                </FormGroup>

                {this.getStatusText(this.getCode('order_hook').status)}

                <hr />

                <p>Для отслеживания просматриваемых посетителями товаров установите следующий код на страницу товарной карточки<br/>Помните, что для работы таких триггеров обязательно наличие корректного YML-фида, указанного в <Link to="/profile/settings/shop">настройках магазина</Link>.</p>
                <p>В приведенный ниже JS-код необходимо делать подстановку уникального идентификатор товара, вместо #item_id#. <br />Идентификатор должен соответствовать приведенному в YML-фиде.</p>

                <FormGroup>
                  <Label for="cartViewInput">Код просмотра товарной карточки</Label>
                  <Input 
                    id="cartViewInput"
                    type="textarea"
                    value={this.getCode('product_page').code}
                    disabled
                  />
                  <CopyToClipboard 
                    text={this.getCode('product_page').code}
                    onCopy={() => this.pinCopiedAlert()}
                  >
                    <Button className="mt-2" color="primary">Скопировать код</Button>
                  </CopyToClipboard>
                </FormGroup>

                {this.getStatusText(this.getCode('product_page').status)}
              </div>
            </div>
          </div>
        </ContentLoading>
      </ContentWrapper>
    );
  }
}

export default ProfileIntegrationSettings;
