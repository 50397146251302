import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => 
      rest.isAuthenticated 
        ? 
          (
            rest.isAdmin ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ 
                  pathname: "/"
                }}
              />
            )
          )
        : (
        <Redirect
          to={{ 
            pathname: "/admin/login", 
            state: { 
              nextPathname: rest.location.pathname, 
              nextSearch: rest.location.search 
            } 
          }}
        />
      )
    }
  />
);

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAdmin
  }),
  null
)(AdminRoute);
