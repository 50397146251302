import axios from 'utils/axios';

export const fetchAdminBlacklist = (query = '') =>
  axios.get('/control/blacklist/get/all', {
    params: {
      search: query
    }
  });

export const fetchAdminModerationPreviewForm = (userId) =>
  axios.get(`/control/moderation/preview/form/${userId}`);

export const fetchAdminModerationPreviewLetter = (userId) =>
  axios.get(`/control/moderation/preview/letter/${userId}`);

export const fetchAdminModerationAll = () =>
  axios.get('/control/moderation/get/all');

export const fetchAdminPaymentHistory = () =>
  axios.get('/control/payment/history/get');

export const fetchAdminPaymentPackages = () =>
  axios.get('/control/payment/packages/all');

export const fetchAdminPaymentUsers = () =>
  axios.get('/control/payment/users/get');

export const fetchAdminUserInfo = (userId) =>
  axios.get(`/control/users/${userId}/info/get`);

export const adminUsersInfoBalanceAdd = (userId, packageId) =>
  axios.post(`/control/users/${userId}/info/balance/${packageId}/add`);

export const fetchAdminUsers = (query = '') =>
  axios.get('/control/users/get/all', {
    params: {
      search: query
    }
  });

export const adminBlacklistEmailRestore = (emailId) =>
  axios.post(`/control/blacklist/email/${emailId}/restore`);

export const adminUsersBlacklistAdd = (params) =>
  axios.post('/control/users/blacklist/add', params);

export const adminModerationApprove = (letterId) =>
  axios.post(`/control/moderation/${letterId}/approve`);

export const adminPaymentPackagesAdd = (params) =>
  axios.post('/control/payment/packages/add', params);

export const adminPaymentUsersPay = (userId, packageId) =>
  axios.post(`/control/payment/users/${userId}/${packageId}/pay`);

export const adminPaymentPackagesDelete = (packageId) =>
  axios.post(`/control/payment/packages/${packageId}/delete`);

export const adminPaymentPackagesUpdate = (packageId, params) =>
  axios.post(`/control/payment/packages/${packageId}/update`, params);

export const adminUsersShopSave = (userId, params) =>
  axios.post(`/control/users/${userId}/info/shop/save`, params);

export const adminUsersEmailResend = (userId) =>
  axios.post(`/control/users/${userId}/info/email/actvation/resend`);

export const adminUsersFeedUpdate = (userId, params) =>
  axios.post(`/control/users/${userId}/info/feed/update`, params);

export const adminUserBlock = (userId) =>
  axios.post(`/control/users/${userId}/block`);

export const adminUserUnblock = (userId) =>
  axios.post(`/control/users/${userId}/unblock`);

export const adminAuthLogin = (params) =>
  axios.post('/hidden/control/auth/admin/login', params);
