export const getAllUrlParams = url => {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};

export const localStringToNumber = (s) => {
  return Number(String(s).replace(/[^0-9.]+/g, ''));
};

export const deepObjectMerge = (destination, source) => {
  const newDestination = { ...destination };
  const newSource = { ...source };

  for (const property in newSource) {
    if (typeof newSource[property] === 'object' && newSource[property] !== null) {
      newDestination[property] = {
        ...newDestination[property],
        ...newSource[property]
      };
    } else {
      newDestination[property] = newSource[property];
    }
  }

  return newDestination;
};

export const getMonthName = (monthNum) => {
  const monthsNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
  let monthName = '';

  if (monthsNames[monthNum - 1]) {
    monthName = monthsNames[monthNum - 1];
  }

  return monthName;
};

export const getDayEnding = (iNumber) => {
  let sEnding, i, aEndings = ['день', 'дня', 'дней']; // 1 4 5
  
  iNumber = Number(iNumber) % 100;

  if (iNumber >= 11 && iNumber <= 19) {
      sEnding = aEndings[2];
  } else {
    i = iNumber % 10;

    switch (i) {
      case (1): sEnding = aEndings[0]; break;
      case (2):
      case (3):
      case (4): sEnding = aEndings[1]; break;
      default: sEnding = aEndings[2];
    }
  }

  return sEnding;
};
