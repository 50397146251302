import axios from 'utils/axios';

export const fetchTags = () =>
  axios.get('/tags/get');

export const fetchTagById = (tagId) =>
  axios.get(`/tags/${tagId}/get`);

export const tagsDelete = (tagId) =>
  axios.post(`/tags/${tagId}/delete`);

export const tagsAdd = (params) =>
  axios.post('/tags/add', params);

export const tagsSave = (tagId, params) =>
  axios.post(`/tags/${tagId}/save`, params);
