import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

// Components
import ContentWrapper from 'components/ContentWrapper';

import './PaymentErrorPage.sass';

const PaymentErrorPage = () => (
  <ContentWrapper>
    <div className="payment-result-page-wrapper">

      <div className="payment-result-info-block">
        <img src={require('assets/img/error-icon.png')} alt="" />
        
        <h2 className="info-title">Что-то пошло не так! <br/> Оплата не произведена</h2>
        
        <Link to="/payment">
          <Button
            className="info-link"
            color="danger"
            size="lg"
          >
            Повторить попытку
          </Button>
        </Link>
      </div>

    </div>
  </ContentWrapper>
);

export default PaymentErrorPage;
