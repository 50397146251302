import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Spinner
} from 'reactstrap';
import {
  fetchSettingsShop,
  settingsShopSave,
  settingsEmailActivationResend,
  settingsFeedUpdate,
} from 'api';
import timeZones from 'google-timezones-json';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';

// Components
import ContentWrapper from 'components/ContentWrapper';
import FormValidator from 'components/FormValidator';
import Button from 'components/common/Forms/Button';
import ContentLoading from 'components/hoc/ContentLoading';

import './ProfileShopSettings.sass';

class ProfileShopSettings extends Component {

  state = {
    timeZones: Object.values(timeZones).map(zone => ({ id: uuid(), name: zone })),
    shopSettingsForm: {
      shopName: '',
      mainDomain: '',
      YML_URL: '',
      timeZone: '',
      senderName: '',
      senderMail: '',
      mailStatus: ''
    },
    priceData: null,
    shopYMLRequiredError: false,
    shopSettingsLoading: true,
    resendActivationLetterLoading: false,
    shopSettingsErrorLoading: false,
    updateFeedLoading: false,
    shopSettingsSaveLoading: false
  };

  componentDidMount () {
    this.getShopSettings();
  }

  getShopSettings = () => {
    this.setState({
      shopSettingsLoading: true,
      shopSettingsErrorLoading: false
    });

    fetchSettingsShop()
      .then(({ data }) => {
        this.setState({
          shopSettingsLoading: false
        });

        if (data.success) {
          this.setState({
            shopSettingsForm: {
              shopName: data.data.name,
              mainDomain: data.data.domain,
              YML_URL: data.data.price ? data.data.price.url : '',
              timeZone: data.data.timezone ? data.data.timezone : '(GMT+03:00) Moscow+00 - Moscow',
              senderName: data.data.senderName,
              senderMail: data.data.senderMail,
              mailStatus: data.data.mailStatus
            },
            priceData: data.data.price
          });
        } else {
          this.setState({
            shopSettingsErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          shopSettingsLoading: false,
          shopSettingsErrorLoading: true
        });
    });
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
            ...this.state[form.name].errors,
            [input.name]: result
        }
      }
    });

    if (input.name === 'YML_URL') {
      if (value.length === 0) {
        this.setState({
          shopYMLRequiredError: true
        });
      } else {
        this.setState({
          shopYMLRequiredError: false
        })
      }
    }
  };

  hasError = (formName, inputName, method) => {
    return this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method];
  };

  shopSettingsFormSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    if (!hasError) {
      const { shopSettingsForm } = this.state;

      this.setState({
        shopSettingsSaveLoading: true
      });

      const params = {
        name: shopSettingsForm.shopName,
        mirror: shopSettingsForm.mainDomain,
        ymlUrl: shopSettingsForm.YML_URL,
        timezone: shopSettingsForm.timeZone,
        senderName: shopSettingsForm.senderName,
        senderMail: shopSettingsForm.senderMail
      };

      settingsShopSave(params)
        .then(({ data }) => {
          this.setState({
            shopSettingsSaveLoading: false
          });

          if (data.success) {
            this.setState({
              shopSettingsForm: {
                ...shopSettingsForm,
                mailStatus: data.data.mailStatus
              }
            });

            toast.success('Настройки успешно сохранены.');
          } else {
            toast.success('Ошибка при сохранении настроек');
          }
        })
        .catch(error => {
          this.setState({
            shopSettingsSaveLoading: false
          });

          toast.success('Ошибка при сохранении настроек');
        });
    }
  };

  resendActivationLetter = () => {
    this.setState({
      resendActivationLetterLoading: true
    });

    settingsEmailActivationResend()
      .then(({ data }) => {
        this.setState({
          resendActivationLetterLoading: false
        });

        if (data.success) {
          toast.success('Письмо активации отправлено повторно.');
        } else {
          toast.error('Ошибка при отправке письма активации');
        }
      })
      .catch(error => {
        this.setState({
          resendActivationLetterLoading: false
        });

        toast.error('Ошибка при отправке письма активации');
      });
  };

  feedUpdate = () => {
    const { shopSettingsForm, shopYMLRequiredError } = this.state;

    if (shopYMLRequiredError || !shopSettingsForm.YML_URL) {
      this.setState({
        shopYMLRequiredError: true
      });

      return;
    }

    this.setState({
      updateFeedLoading: true
    });

    settingsFeedUpdate({
      priceUrl: shopSettingsForm.YML_URL
    })
      .then(({ data }) => {
        this.setState({
          updateFeedLoading: false
        });

        if (data.success) {
          toast.success('Фид успешно обновлён.');

          this.setState({
            priceData: data.data ? {
              lastUpdate: data.data.date,
              itemsCount: data.data.allItems,
              itemsAvailable: data.data.availableItems
            } : data.data
          });
        } else {
          toast.error('При обновлении фида произошла ошибка, попробуйте позже.');
        }
      })
      .catch(error => {
        this.setState({
          updateFeedLoading: false
        });

        toast.error('При обновлении фида произошла ошибка, попробуйте позже.');
      });
  };

  getTimeZonesOptionsList = () => {
    return this.state.timeZones.map(zone => (
      <option key={zone.id} value={zone.name}>{zone.name}</option>
    ));
  };

  render () {
    const { 
      shopSettingsForm,
      priceData,
      shopYMLRequiredError,
      shopSettingsSaveLoading, 
      updateFeedLoading,
      shopSettingsLoading,
      resendActivationLetterLoading,
      shopSettingsErrorLoading,
    } = this.state;

    return (
      <ContentWrapper>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-12">
              <h2>Настройки магазина</h2>
              <p>Пожалуйста, заполните профиль магазина, это первый этап в процесее интеграции с нашим сервисом. По умолчанию YML-фид обновляется раз в 6 часов, но вы всегда можете запустить принудительное обновление, нажав кнопку "Обновить фид". После внесения изменений в настройки необходимо нажать кнопку "Сохранить" внизу страницы, в противном случчае изменения не будут сохранены.</p>
              <p>Обратите внимание, что при смене почтового адреса на указанную почту отправляется письмо со ссылкой активации. До выполнения активации нового e-mail-адреса путем перехода по ссылке активации из письма, сохраняется статус почтового адреса "не активен" и он будет использован в письмах.</p>
            </div>
          </div>

          <ContentLoading
            isLoading={shopSettingsLoading}
            isError={shopSettingsErrorLoading}
            fetchData={() => this.getShopSettings()}
          >
            <Form name="shopSettingsForm" onSubmit={e => this.shopSettingsFormSubmit(e)}>
              <div className="row">
                <div className="col-xl-6">

                  <FormGroup>
                    <Label for="profileShopNameInput">Название магазна (например Амазон)</Label>
                    <Input
                      id="profileShopNameInput" 
                      name="shopName"
                      type="text"
                      value={shopSettingsForm.shopName}
                      onChange={this.validateOnChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="profileMainLinkInput">Ссылка на основное зеркало сайта (например https://amazon.com)</Label>
                    <Input
                      id="profileMainLinkInput" 
                      name="mainDomain"
                      type="text"
                      value={shopSettingsForm.mainDomain}
                      onChange={this.validateOnChange}
                    />
                  </FormGroup>

                  <InputGroup>
                    <Label id="profile-yml-input-label" for="profileYmlFeedLinkInput">Ссылка на YML-url (например https://amazon.com/feed.yml)</Label>
                    <Input
                      id="profileYmlFeedLinkInput" 
                      name="YML_URL"
                      type="text"
                      value={shopSettingsForm.YML_URL}
                      onChange={this.validateOnChange}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        color="primary"
                        onClick={() => this.feedUpdate()}
                        isLoading={updateFeedLoading}
                      >
                        Обновить фид
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>

                  {shopYMLRequiredError && <p className="text-luminous-red">Поле обязательно для ввода</p>}
                  {priceData ? (
                    <>
                      <p className="text-spring-green">Последнее успешное обновление: {priceData.lastUpdate}</p>
                      <p className="text-spring-green">Всего товаров {priceData.itemsCount}, из них доступно к покупке {priceData.itemsAvaliable}.</p>
                    </>
                  ) : (
                    <p className="text-luminous-red">Фид еще не загружен</p>
                  )}

                </div>
                <div className="col-xl-6 mt-4 mt-xl-0">

                  <FormGroup>
                    <Label>Часовой пояс вашего магазина</Label>
                    <Input
                      name="timeZone"
                      type="select" 
                      value={shopSettingsForm.timeZone} 
                      onChange={this.validateOnChange}
                    >
                      {this.getTimeZonesOptionsList()}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="profileInput">Имя отправителя (для писем, например Магазин Амазон)</Label>
                    <Input
                      id="profileInput" 
                      name="senderName"
                      type="text"
                      value={shopSettingsForm.senderName}
                      onChange={this.validateOnChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="profileInput">Email отправителя (для писем, например: hello@amazon.com)</Label>
                    <Input
                      id="profileInput" 
                      name="senderMail"
                      type="text"
                      value={shopSettingsForm.senderMail}
                      onChange={this.validateOnChange}
                      invalid={this.hasError('shopSettingsForm','senderMail','email')}
                      data-validate='["email"]'
                    />
                    {this.hasError('shopSettingsForm','senderMail','email') && <span className="invalid-feedback">Неверно введен email</span>}
                  </FormGroup>

                  {shopSettingsForm.mailStatus && (
                    <div>
                      Текущий статус email: &nbsp;
                      {shopSettingsForm.mailStatus === 'A' ? (
                        <span className='text-lime-green'>Активен</span>
                      ) : (
                        <>
                          <span className="text-luminous-red">Не активен</span>,
                          {'&nbsp; для активации перейдите по ссылке из письма сервиса. Не пришло письмо активации? Нажмите '}
                          {resendActivationLetterLoading ? (
                            <Spinner 
                              className="mx-2" 
                              size="sm" 
                              color="primary" 
                            />
                          ) : (
                            <>
                              <span 
                                className="mx-2 text-steel-blue link" 
                                onClick={() => this.resendActivationLetter()}
                              >
                                отправить повторно
                              </span>
                              {'или напишите нам по адресу help@boostmy.email'}
                            </>
                          )} 
                        </>
                      )}
                    </div>
                  )}

                  <Button 
                    className="mt-2"
                    color="primary" 
                    size="lg"
                    isLoading={shopSettingsSaveLoading}
                  >
                    Сохранить
                  </Button>

                </div>
              </div>
            </Form>
          </ContentLoading>
        </div>
      </ContentWrapper>
    );
  }
}

export default ProfileShopSettings;
