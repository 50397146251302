import axios from 'utils/axios';

export const triggerDelete = (triggerId) =>
  axios.post(`/triggers/${triggerId}/delete`);

export const triggerStart = (triggerId) =>
  axios.post(`/triggers/${triggerId}/start`);

export const triggerStop = (triggerId) =>
  axios.post(`/triggers/${triggerId}/stop`);

export const triggerAddChild = (triggerId) =>
  axios.post(`/triggers/${triggerId}/add/child`);

export const triggerSaveRules = (triggerId, params) =>
  axios.post(`/triggers/${triggerId}/save/rules`, params);

export const triggerSaveLetter = (triggerId, params) =>
  axios.post(`/triggers/${triggerId}save/letter`, params);

export const fetchTriggerRules = (triggerId) =>
  axios.get(`/triggers/${triggerId}/rules`);

export const fetchTriggerLetter = (triggerId) =>
  axios.get(`/triggers/${triggerId}/letter`);

export const fetchTriggersAll = () =>
  axios.get('/triggers/getall');
    