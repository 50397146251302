import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  FormFeedback,
  Table,
} from 'reactstrap';
import uuid from 'react-uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignInAlt,
  faPlay,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { faExpeditedssl } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';
import {
  fetchAdminUsers,
  adminUserBlock,
  adminUserUnblock,
} from 'api';

// Components
import ContentWrapper from 'components/ContentWrapper';
import SpinnerLoader from 'components/SpinnerLoader';
import ContentLoading from 'components/hoc/ContentLoading';
import Button from 'components/common/Forms/Button';

import './AdminUsersListPage.sass';

class AdminUsersListPage extends Component {

  state = {
    usersList: [],
    usersLoading: false,
    usersErrorLoading: false,
    usersSearchValid: true,
    usersPage: 0,
    usersPerPage: 10,
    addToExceptionLoading: false,
    addUserToExceptionModalOpen: false,
    userToException: null,    
    usersToSearchValue: '',
    usersSearchMode: false
  };

  componentDidMount () {
    this.getUsers();
  }

  getUsers = () => {
    this.setState({
      usersLoading: true,
      usersErrorLoading: false
    });

    fetchAdminUsers()
      .then(({ data }) => {
        this.setState({
          usersLoading: false
        });

        if (data.success) {
          this.setState({
            usersList: data.data
          });
        } else {
          this.setState({
            usersErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          usersLoading: false,
          usersErrorLoading: true
        });
      });
  };

  getStatusName = status => {
    let statusName = '';

    switch (status) {
      case 'active':
        statusName = 'Активен';
        break;

      case 'blocked':
        statusName = 'Заблокирован';
        break;

      case 'pending':
        statusName = 'Ожидает активации';        
        break;

      default:
        statusName = '';
    }

    return statusName;
  };

  getUsersList = () => {
    const { 
      usersList, 
      usersPage, 
      usersPerPage 
    } = this.state;

    let renderUsersList = null;

    if (usersList.length === 0) {
      renderUsersList = (
        <tr>
          <td colSpan="6">
            <div className="text-center">Не найдено.</div>
          </td>
        </tr>
      );

      return renderUsersList;
    }

    renderUsersList = 
      usersList
        .slice(usersPage * usersPerPage, usersPerPage * (usersPage + 1))
        .map((user, i) => (
          <tr key={user.id}>
            <td>{user.company}</td>
            <td>{user.email}</td>
            <td>{user.billing}</td>
            <td>{user.registration}</td>
            <td>{user.status}</td>
            <td>{user.type}</td>
            <td className="text-right">
              {this.getUserControlsList(user.id, user.status)}
            </td>
          </tr>
        ));

    return renderUsersList;
  };

  getUserControlsList = (userId, userStatus) => {
    let controls = [];

    switch (userStatus) {
      case 'Активен':
        controls = ['auth', 'profile', 'active'];
        break;

      case 'Заблокирован':
        controls = ['auth', 'profile', 'blocked'];
        break;

      default:
        controls = ['auth', 'profile'];
    }

    controls = controls.map(control => ({
      id: uuid(),
      name: control
    }));

    const renderControlsList = controls.map((control, i) => {
      let controlItem = null;

      switch (control.name) {
        case 'auth':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.loginUserAsAdmin(userId)}
            >
              <FontAwesomeIcon
                title="Авторизация в кабинет пользоватея"
                icon={faSignInAlt}
              />
            </li>
          );
          break;

        case 'profile':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
            >
              <Link to={`/admin/users/profile/${userId}`}>
                <FontAwesomeIcon
                  title="Перейти в профиль пользователя"
                  icon={faUserCircle}
                />
              </Link>
            </li>
          );          
          break;

        case 'active':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.triggerModalException(userId)}
            >
              <FontAwesomeIcon
                title="Заблокировать пользователя"
                icon={faExpeditedssl}
              />
            </li>
          );
          break;

        case 'blocked':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.restoreUserException(userId)}
            >
              <FontAwesomeIcon
                title="Разаблокировать пользователя"
                icon={faPlay}
              />
            </li>
          );          
          break;

        default:
          controlItem = null;
      }

      return controlItem;
    }).filter(control => !!control);

    return (
      <ul className="admin-users-controls-list">
        {renderControlsList}
      </ul>
    );
  };

  getUsersListPagination = () => {
    const { 
      usersList, 
      usersPerPage, 
      usersPage 
    } = this.state;

    let renderUsersPagination = null;
    const pageQuantity = Math.ceil(usersList.length / usersPerPage);

    renderUsersPagination = (
      <Pagination>
        <PaginationItem>
          <PaginationLink first onClick={() => this.setUsersListPage(1)} disabled={usersPage === 0} />
        </PaginationItem>

        <PaginationItem>
          <PaginationLink previous onClick={() => this.setUsersListPage(usersPage + 1 - 1)} disabled={usersPage === 0} />
        </PaginationItem>
        
        {Array.from(Array(pageQuantity).keys())
          .map(page => Number(page) + 1)
          .map(page => (
            <PaginationItem active={usersPage + 1 === page}>
              <PaginationLink
                onClick={() => this.setUsersListPage(page)}
                disabled={usersPage + 1 === page}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
              
        <PaginationItem>
          <PaginationLink next onClick={() => this.setUsersListPage(usersPage + 1 + 1)} disabled={usersPage + 1 === pageQuantity} />
        </PaginationItem>
          
        <PaginationItem>
          <PaginationLink last onClick={() => this.setUsersListPage(pageQuantity)} disabled={usersPage + 1 === pageQuantity} />
        </PaginationItem>
      </Pagination>
    );

    return renderUsersPagination;
  };

  setUsersListPage = page => {
    const { usersList, usersPerPage } = this.state;

    if (page > 0) {

      const lastPage = Math.ceil(usersList.length / usersPerPage);

      if (page > lastPage) {
        this.setState({
          usersPage: lastPage
        });
      } else {
        this.setState({
          usersPage: page - 1
        });
      }
    }
  };

  loginUserAsAdmin = id => {
    alert(`Авторизация под пользователем ${id}`);
  };

  addUserToException = id => {
    const { usersList, addUserToExceptionModalOpen } = this.state;
    let usersListTemp = usersList;
    const userToRestoreIndex = usersListTemp.findIndex(user => user.id === id);
    usersListTemp[userToRestoreIndex].status = 'Заблокирован';

    adminUserBlock(usersListTemp[userToRestoreIndex].id)
      .then(({ data }) => {
        if (data.success) {
          this.setState({
            usersList: usersListTemp,
            addUserToExceptionModalOpen: !addUserToExceptionModalOpen
          });

          toast.success('Пользователь успешно заблокирован.');
        } else {
          toast.error('Ошибка при попытке заблокировать пользователя');
        }
      })
      .catch(error => {
        toast.error('Ошибка при попытке заблокировать пользователя');
      });
  };

  restoreUserException = id => {
    adminUserUnblock(id)
      .then(({ data }) => {
        if (data.success) {
          let usersListTemp = this.state.usersList;
          const userToRestoreIndex = usersListTemp.findIndex(user => user.id === id);
          usersListTemp[userToRestoreIndex].status = 'Активен';
          toast.success(`Пользователь разблокирован.`);

          this.setState({
            usersList: usersListTemp
          });
        } else {
          toast.error('Ошибка при попытке разблокировать пользователя');
        }
      })
      .catch(error => {
        toast.error('Ошибка при попытке разблокировать пользователя');
      });
  };

  usersSearchValueChange = e => {
    if (e.target.value.length < 3) {
      this.setState({
        usersSearchValid: false
      });
    } else {
      this.setState({
        usersSearchValid: true
      });
    }

    this.setState({
      usersToSearchValue: e.target.value
    });

    if (e.target.value.length === 0 && this.state.usersSearchMode) {
      this.getUsers();

      this.setState({
        usersSearchMode: false,
        usersSearchValid: true
      });
    }
  };

  searchUsers = e => {
    e.preventDefault();

    const { usersToSearchValue } = this.state;

    if (usersToSearchValue.length < 3) {
      this.setState({
        usersSearchValid: false
      });

      return;
    }

    this.setState({
      searchUsersLoading: true,
      usersLoading: true
    });

    fetchAdminUsers(usersToSearchValue)
      .then(({ data }) => {
        this.setState({
          searchUsersLoading: false,
          usersLoading: false
        });

        if (data.success) {
          this.setState({
            usersList: data.data.map(user => ({
              ...user,
              id: uuid()
            })),
            usersSearchMode: true
          });
        } else {
          toast.error('Системная ошибка при поиске');
        }
      })
      .catch(error => {
        this.setState({
          searchUsersLoading: false,
          usersLoading: false
        });
        
        toast.error('Системная ошибка при поиске');
      });
  };

  triggerModalException = id => {
    this.setState({
      addUserToExceptionModalOpen: !this.state.addUserToExceptionModalOpen,
      userToException: id
    });
  };

  render () {
    const {
      usersList,
      usersSearchValid,
      usersToSearchValue,
      searchUsersLoading,
      addUserToExceptionModalOpen,
      userToException,
      usersPerPage,
      usersLoading,
      usersErrorLoading
    } = this.state;

    return (
      <ContentWrapper>
        <Modal
          isOpen={addUserToExceptionModalOpen}
          toggle={() => this.setState({ addUserToExceptionModalOpen: !addUserToExceptionModalOpen })}
        >
          <ModalBody className="text-center">
            <h3>Заблокировать пользователя?</h3>
            <p>Пользователь {userToException} не сможет входить в кабинет, все сессии убиваются</p>

            <div>
              <Button 
                color="primary" 
                onClick={() => this.addUserToException(userToException)}
              >
                Заблокировать пользователя
              </Button>

              <Button
                color="secondary"
                className="ml-2"
                onClick={() => this.setState({ addUserToExceptionModalOpen: !addUserToExceptionModalOpen })}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <ContentLoading
          isLoading={false}
          isError={usersErrorLoading}
          fetchData={() => this.getUsers()}
        >
          <Card>
            <CardHeader className="admin-users-card-header">
              <form className="admin-users-search-form" onSubmit={e => this.searchUsers(e)}>
                <InputGroup>
                  <Input 
                    invalid={!usersSearchValid}
                    value={usersToSearchValue} 
                    onChange={e => this.usersSearchValueChange(e)} 
                    placeholder="Введите email или имя компании..." 
                  />
                  
                  <InputGroupAddon addonType="append">
                    <Button type="submit" isLoading={searchUsersLoading}>
                      Найти
                    </Button>
                  </InputGroupAddon>

                  <FormFeedback invalid={usersSearchValid ? false : true}>Не меньше 3 символов для поиска</FormFeedback>
                </InputGroup>
              </form>
            </CardHeader>

            <CardBody>
              <Table className="admin-users-list-table" striped responsive>
                <thead>
                  <tr>
                    <th>Имя компании</th>
                    <th>Email (логин)</th>
                    <th>Баланс посетителей</th>
                    <th>Дата регистрации</th>
                    <th>Статус</th>
                    <th>Тариф</th>
                  </tr>
                </thead>

                <tbody>
                  {usersLoading ? (
                    <tr>
                      <td colSpan="6">
                        <SpinnerLoader />
                      </td>
                    </tr>
                  ) : this.getUsersList()}
                </tbody>
              </Table>

              {!usersLoading && usersList.length > usersPerPage ? (
                <div className="mt-5">
                  {this.getUsersListPagination()}
                </div>
              ) : null}
            </CardBody>
          </Card>
        </ContentLoading>
      </ContentWrapper>
    );
  }
}

export default AdminUsersListPage;
