import React, { Component } from 'react';
import { Card, CardBody, Alert } from 'reactstrap';
import { userLogin, setUserData } from 'store/actions/actions';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from 'utils/axios';
import { userRegisterConfirm } from 'api';

// Components
import ContentLoading from 'components/hoc/ContentLoading';

class ConfirmRegister extends Component {

  state = {
    registerConfirmLoading: true,
    registerConfirmErrorLoading: false,
    registerConfirmError: false, 
    confirmRegisterErrors: []
  };

  componentDidMount () {
    this.registerConfirm();
  }

  registerConfirm = () => {
    const { 
      location,
      userLogin,
      setUserData,
      history
    } = this.props;

    const params = queryString.parse(location.search);

    this.setState({
      registerConfirmLoading: true,
      registerConfirmErrorLoading: false
    });

    userRegisterConfirm({
      email: params.email, 
      token: params.token,
    })
      .then(({ data }) => {
        this.setState({
          registerConfirmLoading: false
        });

        if (data.success) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${data.data.auth_token}`;

          userLogin(data.data.auth_token);
          setUserData(data.data.user.id, data.data.user.name, data.data.user.email);

          localStorage.setItem('authToken', data.data.auth_token);

          setTimeout(() => history.push('/'), 3000);
        } else {
          let confirmRegisterErrors = [];

          for (let error in data.errors) {
            confirmRegisterErrors.push({ id: uuid(), error: data.errors[error] });
          }

          this.setState({
            confirmRegisterErrors,
            registerConfirmError: true
          });
        }
      })
      .catch(error => {
        this.setState({
          registerConfirmLoading: false
        });

        toast.error('Возникла ошибка при подтверждении регистрации');
      });
  };

  render () {
    const { 
      registerConfirmLoading,
      registerConfirmErrorLoading,
      registerConfirmError,
      confirmRegisterErrors 
    } = this.state;

    return (
      <div className="block-center mt-4 wd-xl">
        <Card>
          <CardBody className="text-center">
            <ContentLoading
              isLoading={registerConfirmLoading}
              isError={registerConfirmErrorLoading}
              fetchData={() => this.registerConfirm()}
            >
              <Alert color={registerConfirmError ? 'danger' : 'success'}>
                {registerConfirmError ? (
                  <>
                    <h3 style={{ lineHeight: '35px' }}>Ошибка активации :(</h3>
                    <div>{confirmRegisterErrors.map(error => <div key={error.id}>{error.error}</div>)}</div>
                  </>
                ) : (
                  <>
                    <h3 style={{ lineHeight: '35px' }}>Аккаунт успешно активирован!</h3>
                    <p>Желаем успешной работы!</p>
                    <p>Через 3 секунды Мы перенаправим Вас на Главную страницу</p>
                  </> 
                )}
              </Alert>
            </ContentLoading>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect(
  null,
  { userLogin, setUserData }
)(ConfirmRegister);
