import axios from 'utils/axios';

export const authAdminCheck = () =>
  axios.get('/hidden/control/auth/admin/check');

export const authCheck = () =>
  axios.get('/auth/check');

export const userLogout = () =>
  axios.post('/user/logout');

export const userRegisterConfirm = (params) =>
  axios.post('/user/register/confirm', params);

export const userLogin = (params) =>
  axios.post('/user/login', params);

export const userPasswordResetConfirm = (params) =>
  axios.post('/user/password/reset/confirm', params);

export const userPasswordReset = (params) =>
  axios.post('/user/password/reset', params);

export const userRegister = (params) =>
  axios.post('/user/register', params);
