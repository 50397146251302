import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Card,
  CardBody,
  Button,
  Tooltip,
  Modal,
  ModalBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faChartBar,
  faClone,
  faPlay,
  faPause,
  faCog,
  faTrashAlt,
  faWrench
} from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import {
  fetchTriggersAll,
  triggerDelete,
  triggerStart,
  triggerStop,
  triggerAddChild,
} from 'api';
import { toast } from 'react-toastify';

// Components
import ContentWrapper from 'components/ContentWrapper';
import ContentLoading from 'components/hoc/ContentLoading';
import SpinnerLoader from 'components/SpinnerLoader';

import './TriggersListPage.sass';

class TriggersListPage extends Component {

  state = {
    triggersList: [],
    triggersListNew: [],
    deleteTriggerModalOpen: false,
    triggerToDelete: null,
    triggerToDeleteIsSimple: false,
    triggersLoading: true,
    triggersErrorLoading: true
  };

  componentDidMount () {
    this.getTrigggers();
  }

  getTrigggers = () => {
    this.setState({
      triggersLoading: true,
      triggersErrorLoading: false
    });

    fetchTriggersAll()
      .then(({ data }) => {
        this.setState({
          triggersLoading: false
        });

        if (data.success) {
          if (data?.data?.map?.length > 0) {
            this.setState({
              triggersList: data.data.map.map(trigger => ({
                ...trigger,
                tooltipOpen: false
              }))
            });
          }

          if (data?.data?.simple?.length > 0) {
            this.setState({
              triggersListNew: data.data.simple.map(trigger => ({
                ...trigger,
                tooltipOpen: false
              }))
            });
          }
        } else {
          this.setState({
            triggersErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          triggersErrorLoading: true,
          triggersLoading: false
        });
      });
  };

  triggerRemove = (id, isSimple = false) => {
    const triggersKey = isSimple ? 'triggersListNew' : 'triggersList';
    const { deleteTriggerModalOpen } = this.state;

    triggerDelete(id)
      .then(({ data }) => {
        if (data.success) {
          let triggersListTemp = this.state[triggersKey];
          const triggerToEditIndex = this.state[triggersKey].findIndex(trigger => trigger.id === id);
          triggersListTemp.splice(triggerToEditIndex, 1);

          toast.success(`Триггер ${triggersListTemp[triggerToEditIndex].name} удален`);

          this.setState({
            [triggersKey]: triggersListTemp,
            deleteTriggerModalOpen: !deleteTriggerModalOpen
          });
        } else {
          toast.error('Ошибка при удалении триггера');
        }
      })
      .catch(error => {
        toast.error('Ошибка при удалении триггера');
      });
  };

  triggerStart = (id, isSimple = false) => {
    const triggersKey = isSimple ? 'triggersListNew' : 'triggersList';

    triggerStart(id)
      .then(({ data }) => {
        if (data.success) {
          let triggersListNew = this.state[triggersKey];
          const triggerToStartIndex = triggersListNew.findIndex(trigger => trigger.id === id);

          if (triggersListNew[triggerToStartIndex].status === 'S') {
            triggersListNew[triggerToStartIndex].status = 'A';
          } else if (triggersListNew[triggerToStartIndex].status === 'SB') {
            triggersListNew[triggerToStartIndex].status = 'AB';
          }

          toast.success(`Триггер ${triggersListNew[triggerToStartIndex].name} запущен.`);

          this.setState({
            [triggersKey]: triggersListNew
          });
        } else {
          toast.error('Ошибка при запуске триггера');
        }
      })
      .catch(error => {
        toast.error('Ошибка при запуске триггера');
      });
  };

  triggerPause = (id, isSimple = false) => {
    const triggersKey = isSimple ? 'triggersListNew' : 'triggersList';

    triggerStop(id)
      .then(({ data }) => {
        if (data.success) {
          let triggersListNew = this.state[triggersKey];

          const triggerToStopIndex = triggersListNew.findIndex(trigger => trigger.id === id);

          if (triggersListNew[triggerToStopIndex].status === 'A') {
            triggersListNew[triggerToStopIndex].status = 'S';
          } else if (triggersListNew[triggerToStopIndex].status === 'AB') {
            triggersListNew[triggerToStopIndex].status = 'SB';
          }

          toast.success(`Триггер ${triggersListNew[triggerToStopIndex].name} приостановлен.`);

          this.setState({
            [triggersKey]: triggersListNew
          });
        } else {
          toast.error('Ошибка при остановке триггера');
        }
      })
      .catch(error => {
        toast.error('Ошибка при остановке триггера');
      });
  };

  triggerModalDeleteTag = (id, isSimple = false) => {
    this.setState({
      deleteTriggerModalOpen: !this.state.deleteTriggerModalOpen,
      triggerToDelete: id,
      triggerToDeleteIsSimple: isSimple
    });
  };

  triggerDuplicate = (id, isSimple = false) => {
    const triggersKey = isSimple ? 'triggersListNew' : 'triggersList';

    triggerAddChild(id).then(({ data }) => {
      if (data.success) {
        let triggersListNew = this.state[triggersKey];
        const triggerToDuplicateIndex = triggersListNew.findIndex(trigger => trigger.id === id);

        this.getTrigggers();

        toast.success(`Триггер ${triggersListNew[triggerToDuplicateIndex].name} дублирован.`);
      } else {
        toast.error('Ошибка при дублировании триггера');
      }
    }).catch(error => {
      toast.error('Ошибка при дублировании триггера');
    });
  };

  toggleTriggerTooltip = (id, isSimple = false) => {
    const triggersKey = isSimple ? 'triggersListNew' : 'triggersList';
    let triggersListNew = this.state[triggersKey];
    const triggerIndex = triggersListNew.findIndex(trigger => trigger.id === id);
    triggersListNew[triggerIndex].tooltipOpen = !triggersListNew[triggerIndex].tooltipOpen;

    this.setState({ triggersList: triggersListNew });
  };

  getTriggerTooltipState = (id, isSimple = false) => {
    const triggersKey = isSimple ? 'triggersListNew' : 'triggersList';
    return this.state[triggersKey].find(trigger => trigger.id === id).tooltipOpen;
  };

  getTriggerList = (triggers, isSimple = false) => {
    let renderTriggerList = null;

    renderTriggerList = triggers.map((trigger, i) => {
      let tooltip = null;

      if (trigger.info) {
        tooltip = (
          <>
            <span id={`triggerTooltip${trigger.id}`}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
            <Tooltip
              placement="right"
              isOpen={this.getTriggerTooltipState(trigger.id, isSimple)}
              target={`triggerTooltip${trigger.id}`}
              toggle={() => this.toggleTriggerTooltip(trigger.id, isSimple)}
            >
              {trigger.info}
            </Tooltip>
          </>
        );
      }

      return (
        <tr key={trigger.id}>
          <td>{trigger.name} {tooltip}</td>
          <td>{this.getTriggerStatusName(trigger.status, isSimple)}</td>
          <td className="text-right">
            {this.getTriggerControlsList(trigger.id, trigger.status, trigger.type, isSimple)}
          </td>
        </tr>
      );
    });

    return renderTriggerList;
  };

  getTriggerControlsList = (triggerId, status, type, isSimple = false) => {
    let controls = [];

    switch (status) {
      case 'A':
        controls = [
          'information',
          'duplicate',
          'pause',
          'settings'
        ];
        break;

      case 'S':
        controls = [
          'information',
          'duplicate',
          'play',
          'settings'
        ];
        break;

      case 'W':
        controls = ['integration'];
        break;

      case 'B':
        const renderControlsList = <div>Доступно на платном тарифе</div>;
        return renderControlsList;

      case 'AB':
        controls = [
          'information',
          'pause',
          'settings'
        ];
        break;

      case 'SB':
        controls = [
          'information',
          'play',
          'settings'
        ];
        break;

      default:
    }

    if (type === 'child') {
      controls.push('delete');
    }

    if (isSimple) {
      controls = controls.filter(control => control !== 'duplicate' && control !== 'delete');
    }

    controls = controls.map(control => ({
      id: uuid(),
      name: control
    }));

    const renderControlsList = controls.map((control, i) => {
      let controlItem = null;

      switch (control.name) {
        case 'integration':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
            >
              <Link to="/profile/settings/integration">
                <FontAwesomeIcon icon={faWrench} />
              </Link>
            </li>
          );
          break;
          
        case 'information':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
            >
              <Link to="/?stat=trigger">
                <FontAwesomeIcon icon={faChartBar} />
              </Link>
            </li>
          );
          break;
          
        case 'duplicate':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.triggerDuplicate(triggerId,  isSimple)}
            >
              <FontAwesomeIcon icon={faClone} />
            </li>
          );
          break;
          
        case 'pause':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.triggerPause(triggerId,  isSimple)}
            >
              <FontAwesomeIcon icon={faPause} />
            </li>
          );
          break;
          
        case 'play':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.triggerStart(triggerId,  isSimple)}
            >
              <FontAwesomeIcon icon={faPlay} />
            </li>
          );
          break;
          
        case 'settings':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
            >
              <Link to={`/trigger/${triggerId}/edit`}>
                <FontAwesomeIcon icon={faCog} />
              </Link>
            </li>
          );
          break;

        case 'delete':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.triggerModalDeleteTag(triggerId,  isSimple)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </li>
          );
          break;

        default:
      }

      return controlItem; 
    });

    return <ul className="triggers-controls-list">{renderControlsList}</ul>;
  };

  getTriggerStatusName = status => {
    let statusName = '';

    switch (status) {
      case 'A':
        statusName = 'Активен';
        break;

      case 'S':
        statusName = 'Приостановлен';
        break;

      case 'notactive':
        statusName = 'Не активен';
        break;

      case 'W':
        statusName = 'Требует интеграции';
        break;

      default:
    }

    return statusName;
  };

  render () {
    const { 
      triggersList,
      triggersListNew,
      deleteTriggerModalOpen, 
      triggerToDelete, 
      triggerToDeleteIsSimple,
      triggersLoading,
      triggersErrorLoading ,
    } = this.state;

    return (
      <ContentWrapper>
        <Modal isOpen={deleteTriggerModalOpen} toggle={() => this.setState({ deleteTriggerModalOpen: !deleteTriggerModalOpen })}>
          <ModalBody className="text-center">
            <h3>Удалить триггер?</h3>
            <p>Все настройки триггера будут удалены. Отменить данное действие невозможно.</p>
            <div>
              <Button
                color="primary"
                onClick={() => this.triggerRemove(triggerToDelete, triggerToDeleteIsSimple)}
              >
                Удалить
              </Button>
              
              <Button 
                color="secondary" 
                className="ml-2" 
                onClick={() => this.setState({ deleteTriggerModalOpen: !deleteTriggerModalOpen })}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Card>
          <CardBody>
            <ContentLoading
              isLoading={false}
              isError={triggersErrorLoading}
              fetchData={() => this.getTrigggers()}
            >
              <Table className="triggers-list-table" striped responsive>
                <thead>
                  <tr>
                    <th>Название триггера</th>
                    <th>Статус</th>
                  </tr>
                </thead>

                <tbody>
                  {triggersLoading ? (
                    <tr>
                      <td colSpan="3">
                        <SpinnerLoader />
                      </td>
                    </tr>
                  ) : this.getTriggerList(triggersList)}
                </tbody>
              </Table>

              <h3 className="mt-5">Новые триггеры</h3>

              <Table className="triggers-list-table" striped responsive>
                <thead>
                  <tr>
                    <th>Название триггера</th>
                    <th>Статус</th>
                  </tr>
                </thead>

                <tbody>
                  {triggersLoading ? (
                    <tr>
                      <td colSpan="3">
                        <SpinnerLoader />
                      </td>
                    </tr>
                  ) : this.getTriggerList(triggersListNew, true)}
                </tbody>
              </Table>
            </ContentLoading>
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

export default TriggersListPage;
