import axios from 'utils/axios';

export const fetchStatData = (statType, params) =>
  axios.get(`/stat/${statType}/get`, {
    params,
  });

export const fetchStatChartType = (statType, chartType, params) =>
  axios.get(`/stat/chart/${statType}/${chartType}`, {
    params,
  });

export const fetchStatChart = (statType, params) =>
  axios.get(`/stat/chart/${statType}`, {
    params,
  });
