import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => 
      rest.isAuthenticated 
        ? <Component {...props} />
        : (
          <Redirect
            to={{ 
              pathname: "/login", 
              state: { 
                nextPathname: rest.location.pathname, 
                nextSearch: rest.location.search 
              } 
            }}
          />
        )
    }
  />
);

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated
  }),
  null
)(PrivateRoute);
