import React, { Component } from 'react';
import { 
  Form, 
  FormGroup, 
  Label, 
  Input, 
  Table,
  Row,
  Col,
  Alert,
  Modal,
  ModalBody,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faCog
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import { localStringToNumber } from 'utils/helpers';
import {
  fetchAdminPaymentHistory,
  fetchAdminPaymentPackages,
  fetchAdminPaymentUsers,
  adminPaymentPackagesAdd,
  adminPaymentUsersPay,
  adminPaymentPackagesDelete,
  adminPaymentPackagesUpdate,
} from 'api';

import './AdminPaymentPage.sass';

// Components
import ContentWrapper from 'components/ContentWrapper';
import SpinnerLoader from 'components/SpinnerLoader';
import ContentLoading from 'components/hoc/ContentLoading';
import Button from 'components/common/Forms/Button';

class AdminPaymentPage extends Component {

  state = {
    paymentsHistoryList: [],
    paymentsHistoryPerPage: 5,
    paymentsHistoryPage: 0,

    paymentInfoLoading: true,
    paymentsHistoryLoading: false,
    paymentInfoErrorLoading: false,
    paymentsHistoryErrorLoading: false,
    
    usersToAddVisitsList: [],
    usersToAddVisitsLoading: false,
    usersToAddVisitsErrorLoading: false,
    userToAddVisits: '',
    
    visitsToAddUser: '',
    
    visitsPackagesList: [],
    visitsPackagesErrorLoading: false,
    visitsPackagesLoading: false,

    addNewPackageForm: {
      visitsNum: '',
      visitsPrice: ''
    },
    addNewPackageErrors: [],
    addNewPackageError: false,

    saveEditingPackageErrors: [],
    saveEditingPackageError: false,

    userAddVisitsLoading: false,

    addNewPackageModalOpen: false,
    userAddVisitsModalOpen: false,

    deletePackageModalOpen: false,
    deletePackageLoading: false,

    packageToEdit: null,
    packageToDelete: null,

    packageModalType: 'new'
  };

  componentDidMount () {
    this.getPaymentsHistory();
    this.getVisitPackages();
    this.getPaymentUsers();
  }

  getPaymentsHistory = () => {
    this.setState({
      paymentsHistoryLoading: true,
      paymentsHistoryErrorLoading: false
    });

    fetchAdminPaymentHistory()
      .then(({ data }) => {
        this.setState({
          paymentsHistoryLoading: false
        });

        if (data.success) {
          this.setState({
            paymentsHistoryLoading: false,
            paymentsHistoryList: data.data
          });
        } else {
          this.setState({
            paymentsHistoryErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          paymentsHistoryLoading: false,
          paymentsHistoryErrorLoading: true
        });
      });
  };

  getVisitPackages = () => {
    this.setState({
      visitsPackagesLoading: true,
      visitsPackagesErrorLoading: false
    });

    fetchAdminPaymentPackages()
      .then(({ data }) => {
        this.setState({
          visitsPackagesLoading: false
        });

        if (data.success) {
          this.setState({
            visitsPackagesList: data.data,
            visitsToAddUser: data.data[0].id
          });
        } else {
          this.setState({
            visitsPackagesErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          visitsPackagesLoading: false,
          visitsPackagesErrorLoading: true
        });
      });
  };

  getPaymentUsers = () => {
    this.setState({
      usersToAddVisitsLoading: true,
      usersToAddVisitsErrorLoading: false
    });

    fetchAdminPaymentUsers()
      .then(({ data }) => {
        this.setState({
          usersToAddVisitsLoading: false
        });

        if (data.success) {
          this.setState({
            usersToAddVisitsList: data.data,
            userToAddVisits: data.data[0].id
          });
        } else {
          this.setState({
            usersToAddVisitsErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          usersToAddVisitsLoading: false,
          usersToAddVisitsErrorLoading: true
        });
      });
  };

  getPaymentsHistoryList = () => {
    const { 
      paymentsHistoryList,
      paymentsHistoryPerPage, 
      paymentsHistoryPage, 
    } = this.state;

    let renderPaymentsHistoryList = null;

    if (paymentsHistoryList.length === 0) {
      renderPaymentsHistoryList = (
        <tr>
          <td colSpan="3" className="text-center">
            Не найдено
          </td>
        </tr>
      );    

      return renderPaymentsHistoryList; 
    }

    renderPaymentsHistoryList = paymentsHistoryList
      .slice(paymentsHistoryPage * paymentsHistoryPerPage, paymentsHistoryPerPage * (paymentsHistoryPage + 1))
      .map((payment, i) => {
        return (
          <tr key={payment.id}>
            <td>{payment.name}</td>
            <td>{payment.price}</td>
            <td>{payment.payed_at}</td>
          </tr>
        );
      });

    return renderPaymentsHistoryList;
  };

  getPaymentsHistoryListPagination = () => {
    const { 
      paymentsHistoryList, 
      paymentsHistoryPerPage, 
      paymentsHistoryPage 
    } = this.state;

    const pageQuantity = Math.ceil(paymentsHistoryList.length / paymentsHistoryPerPage);
    let renderPaymentsHistoryPagination = null;

    renderPaymentsHistoryPagination = (
      <Pagination>
        <PaginationItem>
          <PaginationLink first onClick={() => this.setPaymentsHistoryListPage(1)} disabled={paymentsHistoryPage === 0} />
        </PaginationItem>

        <PaginationItem>
          <PaginationLink previous onClick={() => this.setPaymentsHistoryListPage(paymentsHistoryPage + 1 - 1)} disabled={paymentsHistoryPage === 0} />
        </PaginationItem>
        
        {Array.from(Array(pageQuantity).keys())
          .map(page => Number(page) + 1)
          .map((page, i) => (
            <PaginationItem key={i} active={paymentsHistoryPage + 1 === page}>
              <PaginationLink
                onClick={() => this.setPaymentsHistoryListPage(page)}
                disabled={paymentsHistoryPage + 1 === page}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
              
        <PaginationItem>
          <PaginationLink next onClick={() => this.setPaymentsHistoryListPage(paymentsHistoryPage + 1 + 1)} disabled={paymentsHistoryPage + 1 === pageQuantity} />
        </PaginationItem>
          
        <PaginationItem>
          <PaginationLink last onClick={() => this.setPaymentsHistoryListPage(pageQuantity)} disabled={paymentsHistoryPage + 1 === pageQuantity} />
        </PaginationItem>
      </Pagination>
    );

    return renderPaymentsHistoryPagination;
  };

  setPaymentsHistoryListPage = page => {
    const { paymentsHistoryList, paymentsHistoryPage } = this.state;

    if (page > 0) {
      const lastPage = Math.ceil(paymentsHistoryList.length / paymentsHistoryPage);

      if (page > lastPage) {
        this.setState({
          paymentsHistoryPage: lastPage
        });
      } else {
        this.setState({
          paymentsHistoryPage: page - 1
        });
      }
    }
  };

  getVisitsPackagesList = () => {
    const { visitsPackagesList } = this.state;
    let renderVisitsPackagesList = null;

    if (visitsPackagesList.length === 0) {
      renderVisitsPackagesList = (
        <tr>
          <td colSpan="4" className="text-center">
            Не найдено
          </td>
        </tr>
      );    

      return renderVisitsPackagesList;  
    }

    renderVisitsPackagesList = visitsPackagesList.map((visitsPackage, i) => {
      return (
        <tr key={visitsPackage.id}>
          <td>{visitsPackage.name}</td>
          <td>{visitsPackage.sendings}</td>
          <td className="text-right">
            {this.getVisitsPackagesControlsList(visitsPackage.id)}
          </td>
        </tr>
      );
    });

    return renderVisitsPackagesList;
  };

  getVisitsPackagesControlsList = (packageId) => {
    let controls = ['settings', 'delete'];

    controls = controls.map(control => ({
      id: uuid(),
      name: control
    }));

    const renderControlsList = controls.map((control, i) => {
      let controlItem = null;

      switch (control.name) {
        case 'settings':
          controlItem = <li key={control.id} className="control-item" onClick={() => this.triggerModalEditPackage(packageId)}><FontAwesomeIcon title="Изменить пакет" icon={faCog} /></li>;
          
          break;

        case 'delete':
          controlItem = <li key={control.id} className="control-item" onClick={() => this.triggerModalDeletePackage(packageId)}><FontAwesomeIcon title="Удалить пакет" icon={faTrashAlt} /></li>;
          
          break;

        default:
          controlItem = null;
      }

      return controlItem;
    }).filter(control => !!control);

    return (
      <ul className="payment-visits-packages-controls-list">
        {renderControlsList}
      </ul>
    );
  };

  visitsPackageSelectChange = visitsPackage => {
    this.setState({
      visitsToAddUser: visitsPackage
    });
  };

  userToAddVisitsSelectChange = user => {
    this.setState({
      userToAddVisits: user
    });
  };

  addNewPackage = () => {
    this.setState({
      addNewPackageLoading: true,
      addNewPackageError: false
    });

    const { addNewPackageForm } = this.state;

    adminPaymentPackagesAdd({
      price: addNewPackageForm.visitsPrice,
      users: addNewPackageForm.visitsNum,
    })
      .then(({ data }) => {
        this.setState({
          addNewPackageLoading: false
        });

        if (data.success) {
          this.setState({
            addNewPackageModalOpen: false
          });

          toast.success('Пакет успешно добавлен');

          this.getVisitPackages();
        } else {
          let addNewPackageErrors = [];

          addNewPackageErrors.push({ id: uuid(), error: data.error });

          // for (let error in data.errors) {
          //     addNewPackageErrors.push({ id: uuid(), error: data.errors[error] });
          // }

          this.setState({
            addNewPackageError: true,
            addNewPackageErrors
          });

          toast.error('Ошибка при добавлении пакета');
        }
      })
      .catch(error => {
        this.setState({
          addNewPackageLoading: false
        });

        toast.error('Ошибка при добавлении пакета');
      });
  };

  userAddVisits = () => {
    this.setState({
      userAddVisitsLoading: true
    });

    const {
      userToAddVisits,
      visitsToAddUser
    } = this.state;

    adminPaymentUsersPay(userToAddVisits, visitsToAddUser)
      .then(({ data }) => {
        this.setState({
          userAddVisitsLoading: false
        });

        if (data.success) {
          toast.success('Операция выполнена успешно');

          this.setState({
            userAddVisitsModalOpen: false
          });
        } else {
          toast.error('Ошибка при попытке пополнить баланс');
        }
      })
      .catch(error => {
        this.setState({
          userAddVisitsLoading: false
        });

        toast.error('Ошибка при попытке пополнить баланс');
      });
  };

  addNewPackageElemChange = (name, value) => {
    this.setState({
      addNewPackageForm: {
        ...this.state.addNewPackageForm,
        [name]: value
      }
    });
  };

  deletePackage = () => {
    this.setState({
      deletePackageLoading: true
    });

    const { 
      visitsPackagesList,
      packageToDelete
    } = this.state;

    adminPaymentPackagesDelete(packageToDelete)
      .then(({ data }) => {
        this.setState({
          deletePackageLoading: true
        });

        if (data.success) {
          const visitsPackagesListTemp = [...visitsPackagesList];
          const packageToDeleteIndex = visitsPackagesListTemp.findIndex(visitsPackage => visitsPackage.id === packageToDelete);
          visitsPackagesListTemp.splice(packageToDeleteIndex, 1);

          this.setState({
            visitsPackagesList: visitsPackagesListTemp,
            deletePackageModalOpen: false
          });

          toast.success('Пакет удален');
        } else {
          toast.error('Ошибка при попытке удаления пакета');
        }
      })
      .catch(error => {
        this.setState({
          deletePackageLoading: true
        });

        toast.error('Ошибка при попытке удаления пакета');
      });
  };

  saveEditingPackage = () => {
    this.setState({
      saveEditingPackageLoading: true,
      saveEditingPackageError: false
    });

    const { 
      visitsPackagesList,
      packageToEdit,
      addNewPackageForm
    } = this.state;

    adminPaymentPackagesUpdate(packageToEdit, {
      price: addNewPackageForm.visitsPrice,
      users: addNewPackageForm.visitsNum,
    })
      .then(({ data }) => {
        this.setState({
          saveEditingPackageLoading: false
        });

        if (data.success) {
          const visitsPackagesListTemp = [...visitsPackagesList];

          const packageToEditIndex = visitsPackagesListTemp.findIndex(visitsPackage => visitsPackage.id === packageToEdit);

          visitsPackagesListTemp[packageToEditIndex].users = addNewPackageForm.visitsNum;

          this.setState({
            visitsPackagesList: visitsPackagesListTemp,
            addNewPackageModalOpen: false
          });

          toast.success('Изменения умешно сохранены');

          this.getVisitPackages();
        } else {
          let saveEditingPackageErrors = [];

          saveEditingPackageErrors.push({ id: uuid(), error: data.error });

          // for (let error in data.errors) {
          //     saveEditingPackageErrors.push({ id: uuid(), error: data.errors[error] });
          // }

          this.setState({
            saveEditingPackageError: true,
            saveEditingPackageErrors
          });
        }
      })
      .catch(error => {
        this.setState({
          saveEditingPackageLoading: true
        });

        toast.error('Ошибка при попытке удаления пакета');
      });
  };

  triggerModalAddNewPackage = () => {
    this.setState({
      addNewPackageModalOpen: !this.state.addNewPackageModalOpen,
      addNewPackageForm: {
        visitsNum: '',
        visitsPrice: ''
      },
      packageModalType: 'new',
      saveEditingPackageError: false,
      addNewPackageError: false
    });
  };

  triggerModalEditPackage = packageId => {
    const { 
      addNewPackageModalOpen, 
      visitsPackagesList
    } = this.state;

    const visitsPackage = visitsPackagesList.find(visitsPackage => visitsPackage.id === packageId);
    const packageForm = {
      visitsNum: visitsPackage.users,
      visitsPrice: localStringToNumber(visitsPackage.name.match(/\(([^)]+)\)/)[1])
    };

    this.setState({
      addNewPackageModalOpen: !addNewPackageModalOpen,
      packageToEdit: packageId,
      addNewPackageForm: {
        ...packageForm
      },
      packageModalType: 'edit',
      addNewPackageError: false,
      saveEditingPackageError: false
    });
  };

  triggerModalUserAddVisits = () => {
    this.setState({
      userAddVisitsModalOpen: !this.state.userAddVisitsModalOpen
    });
  };

  triggerModalDeletePackage = packageId => {
    this.setState({
      deletePackageModalOpen: !this.state.deletePackageModalOpen,
      packageToDelete: packageId
    });
  };

  render () {
    const { 
      paymentsHistoryList,
      paymentsHistoryPerPage,
      paymentsHistoryErrorLoading,
      paymentsHistoryLoading, 
      userToAddVisits,
      visitsToAddUser,
      visitsPackagesErrorLoading,
      addNewPackageLoading,
      visitsPackagesLoading,
      deletePackageLoading,
      addNewPackageForm,
      userAddVisitsLoading,
      addNewPackageModalOpen,
      deletePackageModalOpen,
      userAddVisitsModalOpen,
      usersToAddVisitsList,
      visitsPackagesList,
      packageModalType,
      saveEditingPackageError,
      saveEditingPackageErrors,
      addNewPackageError,
      addNewPackageErrors,
      saveEditingPackageLoading
    } = this.state;

    return (
      <ContentWrapper>
        <Modal isOpen={deletePackageModalOpen} toggle={() => this.triggerModalDeletePackage()}>
          <ModalBody className="text-center">
            <h3>Удалить выбранный пакет</h3>
            <p>Отменить данное действие невозможно. Вы действительно хотите удалить выбранный пакет посещений,</p>

            <div className="mt-4">
              <Button 
                color="primary" 
                onClick={() => this.deletePackage()}
                isLoading={deletePackageLoading}
              >
                Удалить
              </Button>
              
              <Button 
                color="secondary" 
                className="ml-2" 
                onClick={() => this.triggerModalDeletePackage()}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={userAddVisitsModalOpen} toggle={() => this.triggerModalUserAddVisits()}>
          <ModalBody className="text-center">
            <h3>Пополнение баланса</h3>
            <p>Пользователю <b>{userToAddVisits}</b> будет зачислен следующий пакет <b>{visitsToAddUser}</b>. Вы действительно хотите выполнить данную операцию?</p>

            <div className="mt-4">
              <Button 
                color="primary" 
                onClick={() => this.userAddVisits()}
                isLoading={userAddVisitsLoading}
              >
                Пополнить
              </Button>
              
              <Button 
                color="secondary" 
                className="ml-2" 
                onClick={() => this.triggerModalUserAddVisits()}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={addNewPackageModalOpen} toggle={() => this.triggerModalAddNewPackage()}>
          <ModalBody>
            <div className="text-center mb-3">
              <h3>{packageModalType === 'edit' ? 'Изменить' : 'Добавить'} пакет</h3>
              <p>Укажите количество уникальных посещений для данного пакета, а также его стоимост в рублях. Кол-во посещений и стоимость должны быть больше нуля.</p>
            </div>

            {saveEditingPackageError && (
              <Alert color="danger">
                {saveEditingPackageErrors.map(error => <div key={error.id}>{error.error}</div>)}
              </Alert>
            )}

            {addNewPackageError && (
              <Alert color="danger">
                {addNewPackageErrors.map(error => <div key={error.id}>{error.error}</div>)}
              </Alert>
            )}

            <FormGroup>
              <Label for="addNewPackageVisitsNumInput">Количество посещений</Label>

              <Input 
                id="addNewPackageVisitsNumInput"
                type="number" 
                value={addNewPackageForm.visitsNum} 
                onChange={e => this.addNewPackageElemChange('visitsNum', e.target.value)} 
              />
            </FormGroup>

            <FormGroup>
              <Label for="addNewPackagePriceInput">Стоимость в рублях</Label>

              <Input 
                id="addNewPackagePriceInput"
                type="number" 
                value={addNewPackageForm.visitsPrice} 
                onChange={e => this.addNewPackageElemChange('visitsPrice', e.target.value)} 
              />
            </FormGroup>

            <div className="text-center">
              {packageModalType === 'edit' && (
                <Button 
                  color="primary" 
                  onClick={() => this.saveEditingPackage()}
                  isLoading={saveEditingPackageLoading}
                >
                  Сохранить
                </Button>
              )}

              {packageModalType === 'new' && (
                <Button 
                  color="primary" 
                  onClick={() => this.addNewPackage()}
                  isLoading={addNewPackageLoading}
                >
                  Добавить
                </Button>
              )}
              
              <Button 
                color="secondary" 
                className="ml-2" 
                onClick={() => this.triggerModalAddNewPackage()}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <h1>Деньги</h1>

        <div className="payment-history-block mt-4">
          <h4>Пакеты посещений</h4>
          
          <ContentLoading
            isLoading={false}
            isError={visitsPackagesErrorLoading}
            fetchData={() => this.getVisitPackages()}
          >
            <Table className="payment-visits-packages-table" striped responsive>
              <thead>
                <tr>
                  <th>Пакет посещений</th>
                  <th colSpan="2">Отправок писем</th>
                </tr>
              </thead>

              <tbody>
                {visitsPackagesLoading ? (
                  <tr>
                    <td colSpan="4">
                      <SpinnerLoader />
                    </td>
                  </tr>
                ) : this.getVisitsPackagesList()}
              </tbody>
            </Table>

            <Button 
              color="primary" 
              size="lg" 
              onClick={() => this.triggerModalAddNewPackage()}
            >
              Добавить новый пакет
            </Button>
          </ContentLoading>
        </div>

        <div className="replenish-balance-block mt-4">
          <h4>Пополнить баланс пользователя</h4>

          <Form className="add-visits-user-form">
            <Row>
              <Col lg={6} xl={3}>

                <FormGroup>
                  <Label for="userToAddVisitsSelect">Выберите пользователя</Label>
                  <Input 
                    id="userToAddVisitsSelect" 
                    type="select" 
                    value={userToAddVisits} 
                    onChange={e => this.userToAddVisitsSelectChange(e.target.value)} 
                  >
                    {usersToAddVisitsList.map(user => (
                      <option key={user.id} value={user.id}>{user.name}</option>
                    ))}
                  </Input>
                </FormGroup>

              </Col>
              <Col lg={6} xl={3}>

                <FormGroup>
                  <Label for="visitsPackageSelect">Выберите пакет посещений</Label>
                  <Input 
                    id="visitsPackageSelect" 
                    type="select" 
                    value={visitsToAddUser} 
                    onChange={e => this.visitsPackageSelectChange(e.target.value)}
                  >
                    {visitsPackagesList.map(visitsPackage => (
                      <option key={visitsPackage.id} value={visitsPackage.id}>{visitsPackage.name}</option>
                    ))}
                  </Input>
                </FormGroup>

              </Col>
              <Col xs={12} xl={3}>

                <Button 
                  className="add-visits-user-btn"
                  block
                  size="lg" 
                  color="primary" 
                  onClick={() => this.triggerModalUserAddVisits()}
                >
                  Добавить
                </Button>

              </Col>
            </Row>
          </Form>
        </div>

        <div className="payment-history-block mt-4">
          <h4>История покупок</h4>
          
          <ContentLoading
            isLoading={false}
            isError={paymentsHistoryErrorLoading}
            fetchData={() => this.getPaymentsHistory()}
          >
            <Table className="payment-history-table" striped responsive>
              <thead>
                <tr>
                  <th>Пакет посещений</th>
                  <th>Стоимость</th>
                  <th>Дата оплаты</th>
                </tr>
              </thead>

              <tbody>
                {paymentsHistoryLoading ? (
                  <tr>
                    <td colSpan="3">
                      <SpinnerLoader />
                    </td>
                  </tr>
                ) : this.getPaymentsHistoryList()}
              </tbody>
            </Table>

            {!paymentsHistoryLoading && paymentsHistoryList.length > paymentsHistoryPerPage ? (
              <div className="mt-3">
                {this.getPaymentsHistoryListPagination()}
              </div>
            ) : null}
          </ContentLoading>
        </div>
      </ContentWrapper>
    );
  }
}

export default AdminPaymentPage;
