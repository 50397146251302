import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { deepObjectMerge } from 'utils/helpers';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares';
import { persistedState, saveState } from './persisted.store';

export default function configureStore() {
  const createStoreWithMiddleware = compose(
    applyMiddleware(
      ...middlewares,
      thunk
    ),
    
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )(createStore);

  const store = createStoreWithMiddleware(
    reducers,
    deepObjectMerge(createStore(reducers).getState(), persistedState) // second argument overrides the initial state
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState({
      settings: store.getState().settings
    });
  });

  return store;
}
