import axios from 'utils/axios';

export const fetchShopSettings = () =>
  axios.get('/settings/shop/get');

export const shopBlacklistEmailRemove = (emailId) =>
  axios.post(`/shop/blacklist/email/${emailId}/remove`);

export const shopAudienceBlacklistImport = (params) =>
  axios.post('/shop/audience/blacklist/import', params);

export const shopUserDelete = (userId, params) =>
  axios.post(`/shop/user/${userId}/delete`, params);

export const shopUserBlacklistAdd = (userId) =>
  axios.post(`/shop/user/${userId}/blacklist/add`);

export const shopDeleteEmail = (emailId) =>
  axios.post(`/shop/delete/email/${emailId}/`);

export const shopBlacklistEmailAdd = (emailId) =>
  axios.get(`/shop/blacklist/email/${emailId}/add`);

export const fetchShopBlacklist = (params = {}) =>
  axios.get(`/shop/blacklist`, {
    params,
  });

export const fetchShopAudience = (params) =>
  axios.get('/shop/audience', {
    params,
  });

export const fetchShopAudienceExport = (params) =>
  axios.get('/shop/audience/export', {
    params,
  });

export const fetchShopUserTimeline = (userId) =>
  axios.get(`/shop/user/${userId}/timeline`);
