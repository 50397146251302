import React, { Component } from 'react';
import { 
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  CardFooter,
  Modal,
  ModalBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  fetchAdminModerationPreviewForm,
  adminModerationApprove,
} from 'api';

// Components
import ContentWrapper from 'components/ContentWrapper';
import Scrollable from 'components/common/Scrollable';
import ContentLoading from 'components/hoc/ContentLoading';
import Button from 'components/common/Forms/Button';

import './AdminModerationFormPage.sass';

class AdminModerationFormPage extends Component {

  state = {
    formEditorDataTemp: {
      formType: 'popup',
      formName: '',
      domain: '',
      formPreviewPageUrl: '',
      formSize: 'desktop',
      mobile: {
        formWidth: '',
        formHeight: 'auto',
        formBgColor: '#fff',
        formTextColor: '#656565',
        formIconColor: '#656565',
        formButtonBgColor: '#17a2b8',
        formBgImage: '',
        formTitle: 'Форма заголовок',
        formDescr: 'Форма Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, explicabo alias autem numquam doloribus. Tempora quaerat itaque, quis tenetur autem, cupiditate labore eos. Earum quae at iure, voluptatibus nostrum, alias.',
        formInputPlaceholder: 'Введите ваш email',
        formButtonText: 'Подписаться'
      },
      desktop: {
        formWidth: '400',
        formHeight: 'auto',
        formBgColor: '#fff',
        formTextColor: '#656565',
        formIconColor: '#656565',
        formButtonBgColor: '#17a2b8',
        formBgImage: '',
        formTitle: 'Форма заголовок',
        formDescr: 'Форма Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, explicabo alias autem numquam doloribus. Tempora quaerat itaque, quis tenetur autem, cupiditate labore eos. Earum quae at iure, voluptatibus nostrum, alias.',
        formInputPlaceholder: 'Введите ваш email',
        formButtonText: 'Подписаться'
      }
    },

    formData: {
      id: null,
      name: null,
      sender: null,
      params: null
    },

    formLoading: true,
    formLoadingError: true,

    approveUserModalOpen: false,
    rejectUserModalOpen: false,

    rejectUserCommentValue: ''
  };

  componentDidMount () {
    this.getForm();
    document.body.classList.add('moderation-form-page');
  }

  componentWillUnmount () {
    document.body.classList.remove('moderation-form-page');
  }

  getForm = () => {
    this.setState({
      formLoading: true,
      formLoadingError: false
    });

    fetchAdminModerationPreviewForm(this.props.match.params.userId)
      .then(({ data }) => {
        this.setState({
          formLoading: false
        });

        if (data.success && data.data) {
          const {
            id,
            name,
            sender,
            params
          } = data.data;

          this.setState({
            formData: {
              id,
              name,
              sender,
              params
            }
          });
        }
      })
      .catch(error => {
        this.setState({
          formLoading: false,
          formLoadingError: true
        });
      });
  };

  approveUser = () => {
    this.setState({
      approveUserLoading: true
    });

    adminModerationApprove(this.state.formData.id)
      .then(({ data }) => {
        if (data.success) {
          this.setState({
            approveUserLoading: false,
            approveUserModalOpen: false
          });

          toast.success('Данные пользователя утверждены.');   
        } else {
          toast.error('Ошибка при уверждении данных.');   
        }
      })
      .catch(error => {
        toast.error('Ошибка при уверждении данных.');   
      });
  };

  rejectUser = () => {
    this.setState({
      rejectUserLoading: true
    });

    adminModerationApprove(this.state.formData.id)
      .then(({ data }) => {
        if (data.success) {
          this.setState({
            rejectUserLoading: false,
            rejectUserModalOpen: false
          });

          toast.success('Данные пользователя отклонены.');    
        } else {
          toast.error('Ошибка при отклонении данных.');   
        }
      })
      .catch(error => {
        toast.error('Ошибка при отклонении данных.');   
      });
  };

  rejectUserCommentChange = comment => {
    this.setState({
      rejectUserCommentValue: comment
    });
  };

  triggerModalApprove = () => {
    this.setState({
      approveUserModalOpen: !this.state.approveUserModalOpen
    });
  };

  triggerModalReject = () => {
    this.setState({
      rejectUserModalOpen: !this.state.rejectUserModalOpen
    });
  };

  render () {
    const {
      formEditorDataTemp,
      formLoading,
      formLoadingError,
      approveUserModalOpen,
      rejectUserModalOpen,
      approveUserLoading,
      rejectUserCommentValue,
      formData,
      rejectUserLoading,
    } = this.state;

    const { formSize } = formEditorDataTemp;

    return (
      <ContentWrapper>
        <Modal isOpen={approveUserModalOpen} toggle={() => this.triggerModalApprove()}>
          <ModalBody className="text-center">
            <h3>Утвердить изменения?</h3>
            <p>Нажимая "утвердить" вы подтверждаете, что внимательно изучили изменения которые внес пользователь.</p>

            <div>
              <Button 
                color="primary" 
                onClick={() => this.approveUser()}
                isLoading={approveUserLoading}
              >
                Утвердить
              </Button>

              <Button 
                color="secondary" 
                className="ml-2" 
                onClick={() => this.triggerModalApprove()}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={rejectUserModalOpen} toggle={() => this.triggerModalReject()}>
          <ModalBody className="text-center">
            <h3>Отклонить изменения?</h3>
            <p>Напишите комментарий - причину отклонения</p>

            <FormGroup>
              <Input 
                type="textarea" 
                rows="3" 
                value={rejectUserCommentValue} 
                onChange={e => this.rejectUserCommentChange(e.target.value)} 
                placeholder="Поле для ввода комментария для пользователя - он будет отражен в уведомлении, в его личном кабинете"
              />
            </FormGroup>

            <div className="mt-3">
              <Button 
                type="submit"
                color="primary" 
                onClick={e => this.rejectUser()}
                isLoading={rejectUserLoading}
              >
                Отклонить
              </Button>
              
              <Button 
                color="secondary" 
                className="ml-2" 
                onClick={() => this.triggerModalReject()}
                disabled={rejectUserLoading}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <ContentLoading
          isLoading={formLoading}
          isError={formLoadingError}
          fetchData={() => this.getForm()}
        >
          <Card className="moderation-form-card">
            <CardHeader className="moderation-form-card-header">
              <b>Название формы:</b> {formData.name}
            </CardHeader>

            <CardBody>
              <div className={`card-form-col ${formSize === 'mobile' ? 'mobile-format' : ''}`}>
                <Scrollable 
                  height="100%" 
                  className={`card-form-scroll-wrapper ${formSize === 'mobile' ? 'mobile-format' : ''} ${formEditorDataTemp.formType === 'bottom-bar' && formEditorDataTemp[formSize].formHeight > 500 ? 'top-bar' : formEditorDataTemp.formType}`}
                >
                  <div 
                    className={`form-card ${formEditorDataTemp[formSize].formHeight} ${formSize === 'mobile' ? 'mobile-format' : ''} ${formEditorDataTemp.formType === 'bottom-bar' && formEditorDataTemp[formSize].formHeight > 500 ? 'top-bar' : formEditorDataTemp.formType}`}
                    style={{ 
                      width: `${formEditorDataTemp[formSize].formWidth}px`, 
                      maxWidth: `${formEditorDataTemp[formSize].formWidth}px`, 
                      height: `${formEditorDataTemp[formSize].formHeight === 'auto' ? 'auto' : formEditorDataTemp[formSize].formHeight + 'px'}` 
                    }}

                  >
                    <div className="form-card-body" style={{
                      backgroundColor: formEditorDataTemp[formSize].formBgColor, 
                      backgroundImage: `url(${formEditorDataTemp[formSize].formBgImage})`
                    }}>
                      <FontAwesomeIcon 
                        style={{ color: formEditorDataTemp[formSize].formIconColor }} 
                        className="form-close-icon" 
                        icon={faTimes}
                      />

                      <h3 
                        onClick={this.setTitleEdit} 
                        dangerouslySetInnerHTML={{ __html: formEditorDataTemp[formSize].formTitle }} 
                        className="form-title" 
                        style={{ color: formEditorDataTemp[formSize].formTextColor }}
                      ></h3>
                      
                      <p 
                        onClick={this.setDescrEdit} 
                        dangerouslySetInnerHTML={{ __html: formEditorDataTemp[formSize].formDescr }} 
                        className="form-descr" 
                        style={{ color: formEditorDataTemp[formSize].formTextColor }}
                      ></p>

                      <FormGroup className="form-input-wrap">
                        <Input 
                          className="form-input" 
                          type="text" 
                          value={formEditorDataTemp[formSize].formInputPlaceholder} 
                          onChange={e => this.formEditorElementChange('formInputPlaceholder', e.target.value, formSize)}
                        />
                      </FormGroup>

                      <div className="text-center form-button-wrap">
                        <Button 
                          style={{ backgroundColor: formEditorDataTemp[formSize].formButtonBgColor }} 
                          size="lg" 
                          color="info" 
                          className="form-button"
                        >
                          <Input 
                            className="form-button-input" 
                            value={formEditorDataTemp[formSize].formButtonText} 
                            onChange={e => this.formEditorElementChange('formButtonText', e.target.value, formSize)}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>  
                </Scrollable>
              </div>
            </CardBody>

            <CardFooter className="moderation-form-card-footer">
              <div className="text-right">
                <Button color="success" onClick={() => this.triggerModalApprove()}>Утвердить</Button>
                
                <Button
                  className="ml-2"
                  color="danger"
                  onClick={() => this.triggerModalReject()}
                >
                  Отклонить
                </Button>
                
                <Link to="/admin/moderation">
                  <Button className="ml-2" color="primary">Закрыть</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </ContentLoading>
      </ContentWrapper>
    );
  }
}

export default AdminModerationFormPage;
