import React from 'react';
import { connect } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => 
      !rest.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ 
          	pathname: rest.location.state ? rest.location.state.nextPathname : '/' ,
          	search: rest.location.state ? rest.location.state.nextSearch : ''
          }}
        />
      )
    }
  />
);

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAdmin,
  }),
  null
)(AuthRoute);
