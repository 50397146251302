import React, { Component } from 'react';
import { 
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  ModalBody,
  Modal,
  FormGroup,
  Input
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import {
  fetchAdminModerationPreviewLetter,
  adminModerationApprove,
} from 'api';

// Components
import ContentWrapper from 'components/ContentWrapper';
import Scrollable from 'components/common/Scrollable';
import ContentLoading from 'components/hoc/ContentLoading';
import Button from 'components/common/Forms/Button';

import './AdminModerationLetterPage.sass';

class AdminModerationLetterPage extends Component {

  state = {
    letterLoading: true,
    letterLoadingError: false,
    approveUserModalOpen: false,
    rejectUserModalOpen: false,
    rejectUserCommentValue: '',
    letterData: {
      id: null,
      name: null,
      sender: null,
      subject: null,
      html: null
    }
  };

  componentDidMount () {
    this.getLetter();
    document.body.classList.add('moderation-letter-page');
  }

  componentWillUnmount () {
    document.body.classList.remove('moderation-letter-page');
  }

  getLetter = () => {
    this.setState({
      letterLoading: true,
      letterLoadingError: false
    });

    fetchAdminModerationPreviewLetter(this.props.match.params.userId)
      .then(({ data }) => {
        this.setState({
          letterLoading: false
        });

        if (data.success) {
          const {
            id,
            name,
            sender,
            subject,
            html
          } = data.data;

          this.setState({
            letterData: {
              id,
              name,
              sender,
              subject,
              html
            }
          });
        }
      })
      .catch(error => {
        this.setState({
          letterLoading: false,
          letterLoadingError: true
        });
      });
  };

  approveUser = () => {
    this.setState({
      approveUserLoading: true
    });

    adminModerationApprove(this.state.letterData.id)
      .then(({ data }) => {
        if (data.success) {
          this.setState({
            approveUserLoading: false,
            approveUserModalOpen: false
          });

          toast.success('Данные пользователя утверждены.');   
        } else {
          toast.error('Ошибка при уверждении данных.');   
        }
      })
      .catch(error => {
        toast.error('Ошибка при уверждении данных.');   
      });
  };

  rejectUser = () => {
    this.setState({
      rejectUserLoading: true
    });

    adminModerationApprove(this.state.letterData.id)
      .then(({ data }) => {
        if (data.success) {
          this.setState({
            rejectUserLoading: false,
            rejectUserModalOpen: false
          });

          toast.success('Данные пользователя отклонены.');    
        } else {
          toast.error('Ошибка при отклонении данных.');   
        }
      })
      .catch(error => {
        toast.error('Ошибка при отклонении данных.');   
      });
  };

  triggerModalApprove = () => {
    this.setState({
      approveUserModalOpen: !this.state.approveUserModalOpen
    });
  };

  triggerModalReject = () => {
    this.setState({
      rejectUserModalOpen: !this.state.rejectUserModalOpen
    });
  };

  render () {
    const {
      approveUserModalOpen,
      rejectUserModalOpen,
      approveUserLoading,
      rejectUserCommentValue,
      letterLoading,
      letterLoadingError,
      letterData,
      rejectUserLoading
    } = this.state;

    return (
      <ContentWrapper>
        <Modal isOpen={approveUserModalOpen} toggle={() => this.triggerModalApprove()}>
          <ModalBody className="text-center">
            <h3>Утвердить изменения?</h3>
            <p>Нажимая "утвердить" вы подтверждаете, что внимательно изучили изменения которые внес пользователь.</p>

            <div>
              <Button 
                color="primary" 
                onClick={() => this.approveUser()}
                isLoading={approveUserLoading}
              >
                Утвердить
              </Button>

              <Button 
                color="secondary" 
                className="ml-2" 
                onClick={() => this.triggerModalApprove()}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={rejectUserModalOpen} toggle={() => this.triggerModalReject()}>
          <ModalBody className="text-center">
            <h3>Отклонить изменения?</h3>
            <p>Напишите комментарий - причину отклонения</p>

            <FormGroup>
              <Input 
                type="textarea" 
                rows="3" 
                value={rejectUserCommentValue} 
                onChange={e => this.rejectUserCommentChange(e.target.value)} 
                placeholder="Поле для ввода комментария для пользователя - он будет отражен в уведомлении, в его личном кабинете"
              />
            </FormGroup>

            <div className="mt-3">
              <Button 
                type="submit"
                color="primary" 
                onClick={e => this.rejectUser()}
                isLoading={rejectUserLoading}
              >
                Отклонить
              </Button>
              
              <Button 
                color="secondary" 
                className="ml-2" 
                onClick={() => this.triggerModalReject()}
                disabled={rejectUserLoading}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <ContentLoading
          isLoading={letterLoading}
          isError={letterLoadingError}
          fetchData={() => this.getLetter()}
        >
          <Card className="moderation-letter-card">
            <CardHeader className="moderation-letter-card-header">
              <ul className="moderation-letter-header-list">
                <li><b>Триггер:</b> {letterData.name}</li>
                <li><b>Имя отправителя:</b> {letterData.sender}</li>
                <li><b>Тема письма:</b> {letterData.subject}</li>
              </ul>
            </CardHeader>

            <CardBody>
              <Scrollable height="100%">
                <div className="letter-render" dangerouslySetInnerHTML={{ __html: letterData.html }} />
              </Scrollable>
            </CardBody>

            <CardFooter className="moderation-letter-card-footer">
              <div className="text-right">
                <Button
                  color="success"
                  onClick={()  => this.triggerModalApprove()}
                >
                  Утвердить
                </Button>
                
                <Button
                  className="ml-2"
                  color="danger"
                  onClick={() => this.triggerModalReject()}
                >
                  Отклонить
                </Button>
                
                <Link to="/admin/moderation">
                  <Button className="ml-2" color="primary">Закрыть</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </ContentLoading>
      </ContentWrapper>
    );
  }
}

export default AdminModerationLetterPage;
