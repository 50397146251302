import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Card,
  CardBody,
  Tooltip,
  Modal,
  ModalBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestionCircle,
  faChartBar,
  faClone,
  faPlay,
  faPause,
  faCog,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import {
  fetchFormsList,
  formDelete,
  formDuplicate,
  formStart,
  formStop,
  formAdd,
} from 'api';

// Components
import ContentWrapper from 'components/ContentWrapper';
import SpinnerLoader from 'components/SpinnerLoader';
import ContentLoading from 'components/hoc/ContentLoading';
import Button from 'components/common/Forms/Button';

import './FormsListPage.sass';

class FormsListPage extends Component {

  state = {
    formsList: [],
    formsLoading: false,
    formsErrorLoading: false,
    deleteFormModalOpen: false,
    formToDelete: null
  };

  componentDidMount () {
    this.getForms();
  }

  getForms = () => {
    this.setState({
      formsLoading: true,
      formsErrorLoading: false
    });

    fetchFormsList()
      .then(({ data }) => {
        this.setState({
          formsLoading: false
        });

        if (data.success) {
          this.setState({
            formsList: data.data.forms.map(form => ({
              ...form,
              tooltipOpen: false
            }))
          });
        } else {
          this.setState({
            formsErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          formsLoading: false,
          formsErrorLoading: true
        });
      });
  };

  getFormsList = () => {
    const { formsList } = this.state;
    let renderFormsList = null;

    renderFormsList = formsList.map((form, i) => {
      let tooltip = null;

      if (form.info) {
        tooltip = (
          <>
            <span id={`form-tooltip-${form.id}`}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
            
            <Tooltip 
              placement="right" 
              isOpen={this.getFormTooltipState(form.id)} 
              target={`form-tooltip-${form.id}`}
              toggle={()=> this.toggleFormTooltip(form.id)}
            >
              {form.info}
            </Tooltip>
          </>
        );
      }

      return (
        <tr key={form.id}>
          <td>{form.name} {tooltip}</td>
          <td>{this.getStatusName(form.status)}</td>
          <td>{form.type}</td>
          <td className="text-right">
            {this.getFormControlsList(form.id, form.status, form.system === 'Y')}
          </td>
        </tr>
      );
    });

    return renderFormsList;
  };

  getFormControlsList = (formId, formStatus, isSystem) => {
    let controls = [];

    switch (formStatus) {
      case 'A':
        controls = ['information', 'duplicate', 'pause', 'settings'];
        break;

      case 'AB':
        controls = ['information', 'pause', 'settings'];
        break;

      case 'D':
        controls = ['information', 'duplicate', 'play', 'settings'];
        break;

      case 'SB':
        controls = ['information', 'play', 'settings'];
        break;

      case 'B':
        const renderControlsList = <div>Доступно на платном тарифе</div>;
        return renderControlsList;

      default:
    }

    if (!isSystem) {
      controls.push('delete');
    }

    controls = controls.map(control => ({
      id: uuid(),
      name: control
    }));

    const renderControlsList = controls.map((control, i) => {
      let controlItem = null;

      switch (control.name) {
        case 'information':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
            >
              <Link to="/?stat=form">
                <FontAwesomeIcon icon={faChartBar} />
              </Link>
            </li>
          );
          break;

        case 'duplicate':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.formDuplicate(formId)}
            >
              <FontAwesomeIcon icon={faClone} />
            </li>
          );
          break;
        
        case 'pause':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.formPause(formId)}
            >
              <FontAwesomeIcon icon={faPause} />
            </li>
          );
          break;
        
        case 'play':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.formPlay(formId)}
            >
              <FontAwesomeIcon icon={faPlay} />
            </li>
          );
          break;
        
        case 'settings':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
            >
              <Link to={`/form/${formId}/edit`}>
                <FontAwesomeIcon icon={faCog} />
              </Link>
            </li>
          );
          break;
        
        case 'delete':
          controlItem = (
            <li
              key={control.id}
              className="control-item"
              onClick={() => this.triggerModalDeleteForm(formId)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </li>
          );
          break;

        default:
      }

      return controlItem;
    });

    return (
      <ul className="form-controls-list">
        {renderControlsList}
      </ul>
    );
  };

  toggleFormTooltip = id => {
    let formsListNew = this.state.formsList;
    const formIndex = formsListNew.findIndex(form => form.id === id);
    formsListNew[formIndex].tooltipOpen = !formsListNew[formIndex].tooltipOpen;

    this.setState({ 
      formsList: formsListNew 
    });
  };

  getFormTooltipState = id => {
    return this.state.formsList.find(form => form.id === id).tooltipOpen;
  };

  getStatusName = status => {
    let statusName = '';

    switch (status) {
      case 'D':
        statusName = 'Приостановлена';
        break;

      case 'A':
        statusName = 'Активна';
        break;

      default:
    }

    return statusName;
  };

  formRemove = id => {
    formDelete(id)
      .then(({ data }) => {
        if (data.success) {
          let formsListTemp = this.state.formsList;
          const deleteIndex = formsListTemp.findIndex(form => form.id === id);
          formsListTemp.splice(deleteIndex, 1);

          toast.success(`Форма ${formsListTemp[deleteIndex].name} удалена.`);

          this.setState({
            formsList: formsListTemp,
            deleteFormModalOpen: !this.state.deleteFormModalOpen
          });
        } else {
          toast.error('Ошибка при удалении формы');
        }
      })
      .catch(error => {
        toast.error('Ошибка при удалении формы');
      });
  };

  formDuplicate = id => {
    formDuplicate(id)
      .then(({ data }) => {
        if (data.success) {
          let formsListTemp = this.state.formsList;
          const duplicateIndex = formsListTemp.findIndex(form => form.id === id);

          toast.success(`Форма ${formsListTemp[duplicateIndex].name} дублирована.`);

          this.getForms();
        } else {
          toast.error('Ошибка при дублировании формы');
        }
      }).catch(error => {
        toast.error('Ошибка при дублировании формы');
      });
  };

  formPlay = id => {
    formStart(id)
      .then(({ data }) => {
        if (data.success) {
          let formsListTemp = this.state.formsList;
          const formToPlayIndex = formsListTemp.findIndex(form => form.id === id);
          formsListTemp[formToPlayIndex].status = 'A';

          toast.success(`Форма ${formsListTemp[formToPlayIndex].name} запущена.`);

          this.setState({
            formsList: formsListTemp
          });
        } else {
          toast.error('Ошибка при запуске формы');
        }
      }).catch(error => {
        toast.error('Ошибка при запуске формы');
      });
  };

  formPause = id => {
    formStop(id)
      .then(({ data }) => {
        if (data.success) {
          let formsListTemp = this.state.formsList;
          const formToPauseIndex = formsListTemp.findIndex(form => form.id === id);
          formsListTemp[formToPauseIndex].status = 'D';

          toast.success(`Форма ${formsListTemp[formToPauseIndex].name} приостановлена.`);

          this.setState({
            formsList: formsListTemp
          });
        } else {
          toast.error('Ошибка при остановке формы');
        }
      }).catch(error => {
        toast.error('Ошибка при остановке формы');
      });
  };

  createForm = () => {
    this.setState({
      createFormLoading: true
    });

    formAdd()
      .then(({ data }) => {
        this.setState({
          createFormLoading: false
        });

        if (data.success) {
          this.props.history.push(`/form/${data.data.newFormId}/edit`);
        } else {
          toast.error('Ошибка при создании формы');
        }
      })
      .catch(error => {
        this.setState({
          createFormLoading: false
        });
        
        toast.error('Ошибка при создании формы');
      });
  };

  triggerModalDeleteForm = id => {
    this.setState({
      deleteFormModalOpen: !this.state.deleteFormModalOpen,
      formToDelete: id
    });
  };

  render () {
    const {
      deleteFormModalOpen,
      formToDelete,
      formsLoading,
      formsErrorLoading,
      createFormLoading
    } = this.state;

    return (
      <ContentWrapper>
        <Modal
          isOpen={deleteFormModalOpen}
          toggle={() => this.setState({ deleteFormModalOpen: !deleteFormModalOpen })}
        >
          <ModalBody className="text-center">
            <h3>Удалить форму подписки?</h3>
            <p>Все настройки формы подписки будут удалены. Отменить данное действие невозможно.</p>

            <div>
              <Button
                color="primary"
                onClick={() => this.formRemove(formToDelete)}
              >
                Удалить
              </Button>
              
              <Button
                color="secondary"
                className="ml-2"
                onClick={() => this.setState({ deleteFormModalOpen: !deleteFormModalOpen })}
              >
                Отмена
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <ContentLoading
          isLoading={false}
          isError={formsErrorLoading}
          fetchData={() => this.getForms()}
        >
          <Card>
            <CardBody>
              <Table className="formlist-table" striped responsive>
                <thead>
                  <tr>
                    <th>Название формы</th>
                    <th>Статус</th>
                    <th>Тип формы</th>
                  </tr>
                </thead>

                <tbody>
                  {formsLoading ? (
                    <tr>
                      <td colSpan="3">
                        <SpinnerLoader />
                      </td>
                    </tr>
                  ) : this.getFormsList()}
                </tbody>
              </Table>

              <Button
                className="mt-3" 
                color="primary" 
                size="lg" 
                onClick={() => this.createForm()} 
                block
                isLoading={createFormLoading}
              >
                Создать форму
              </Button>
            </CardBody>
          </Card>
        </ContentLoading>
      </ContentWrapper>
    );
  }
}

export default FormsListPage;
