const Menu = [
  {
    heading: 'Главное меню',
  },
  {
    name: 'Информация',
    icon: 'icon-exclamation',
    path: '/'
  },
  {
    name: 'Триггеры',
    icon: 'icon-chart',
    path: '/triggers'
  },
  {
    name: 'Формы подписки',
    icon: 'icon-book-open',
    path: '/subscriptions'
  },
  {
    name: 'Теги',
    icon: 'icon-tag',
    path: '/tags'
  },
  {
    name: 'Аудитория',
    icon: 'icon-people',
    path: '/audience'
  },
  {
    name: 'Оплата',
    icon: 'icon-basket',
    path: '/payment'
  },
  {
    name: 'Админ - Пользователи',
    icon: 'icon-people',
    path: '/admin/users',
    isAdmin: true
  },
  {
    name: 'Админ - Модерация',
    icon: 'icon-note',
    path: '/admin/moderation',
    isAdmin: true
  },
  {
    name: 'Админ - Список исключений',
    icon: 'icon-close',
    path: '/admin/audience/exceptions',
    isAdmin: true
  },
  {
    name: 'Админ - Оплата',
    icon: 'icon-basket',
    path: '/admin/payment',
    isAdmin: true
  },
];

export default Menu;
