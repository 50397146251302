import axios from 'utils/axios';

export const fetchPaymentInfo = () =>
  axios.get('/payment/info/get');

export const fetchPaymentLog = () =>
  axios.get('/payment/log/get');

export const fetchPaymentPayLink = (packageValue) =>
  axios.get(`/payment/package/${packageValue}/pay/link`);
