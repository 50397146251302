import React from 'react';
import {
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';

const RegistrationCreateMessage = () => (
  <Card>
    <CardHeader>
      <h3 className="text-center" style={{ lineHeight: '35px' }}>Почти готово</h3>
    </CardHeader>

    <CardBody>
      <p>Учетная запись создана, теперь нужно ее активировать. Мы отправили вам письмо. Пожалуйста, перейдите по ссылке из письма, чтобы завершить процесс создания Личного кабинета.</p>
    </CardBody>
  </Card>
);

export default RegistrationCreateMessage;
