import React from 'react';
import classNames from 'classnames';
import { Button as ButtonStrap } from 'reactstrap';

// Components
import Spinner from 'components/common/Spinner';

import './Button.sass';

const Button = ({ children, className, disabled, isLoading, ...rest }) => (
  <ButtonStrap
    {...rest}
    className={classNames('bttn', {
      [className]: className,
    })}
    disabled={disabled || isLoading}
  >
    {children}
    {' '}
    {isLoading && <Spinner className='ml-2' size="xs" />}
  </ButtonStrap>
);

export default Button;
