import React from 'react';
import PropTypes from 'prop-types';
import { 
  Button
} from 'reactstrap';

// Components
import Spinner from 'components/common/Spinner';

import './ContentLoading.sass';

const ContentLoadingProps = {
  isLoading: PropTypes.bool,
  spinSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  isError: PropTypes.bool,
  fetchData: PropTypes.func,
  color: PropTypes.string,
};

const ContentLoadingDefaultProps = {
  spinSize: 'sm',
  color: '#00C5D1',
};

const ContentLoading = (props) => {
  const {
    spinSize,
    color,
    isError,
    isLoading,
    fetchData,
    children
  } = props;

  return isError ? (
    <div className="text-center">
      Ошибка при выполнении запроса <br />

      {fetchData && (
        <Button
          className="mt-2"
          size="xs"
          color="secondary"
          onClick={fetchData}
        >
          Повторить запрос
        </Button>
        )}
    </div>
  ) : (
    <>
      {isLoading ? (
        <div className='loadingSpinner_wrap'>
          <Spinner size={spinSize} color={color} />
        </div>
      ) : children}
    </>
  );
};

ContentLoading.propTypes = ContentLoadingProps;
ContentLoading.defaultProps = ContentLoadingDefaultProps;

export default ContentLoading;
