import React, { Component } from 'react';
import { Card, CardBody, Alert } from 'reactstrap';
import { userLogin, setUserData } from 'store/actions/actions';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { settingsUserEmailConfirm } from 'api';

// Components
import ContentLoading from 'components/hoc/ContentLoading';

class ConfirmEmailPage extends Component {

  state = {
    emailConfirmLoading: true,
    emailConfirmErrorLoading: false
  };

  componentDidMount () {
    this.confirmEmail();
  }

  confirmEmail = () => {
    const { location, userLogin, history } = this.props;

    this.setState({
      emailConfirmLoading: true,
      emailConfirmErrorLoading: false
    });

    const params = queryString.parse(location.search);

    settingsUserEmailConfirm({
      email: params.email,
      token: params.token,
    })
      .then(({ data }) => {
        this.setState({
          emailConfirmLoading: false
        });

        if (data.success) {
          userLogin(params.token);
          localStorage.setItem('authToken', params.token);
          setTimeout(() => history.push('/'), 3000);
        } else {
          this.setState({
            emailConfirmErrorLoading: true
          });
        }
      })
      .catch(error => {
        this.setState({
          emailConfirmErrorLoading: true
        });
      });
  };

  render () {
    const { emailConfirmLoading, emailConfirmErrorLoading } = this.state;

    return (
      <div className="block-center mt-4 wd-xl">
        <Card>
          <CardBody className="text-center">
            <ContentLoading
              isLoading={emailConfirmLoading}
              isError={emailConfirmErrorLoading}
              fetchData={() => this.confirmEmail()}
            >
              <Alert className="text-center" color="success">
                <h3 style={{ lineHeight: '35px' }}>Почта успешно активирована!</h3>
                <p>Желаем успешной работы!</p>
                <p>Через 3 секунды Мы перенаправим Вас на Главную страницу</p>
              </Alert>
            </ContentLoading>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect(
  null,
  { userLogin, setUserData }
)(ConfirmEmailPage);
