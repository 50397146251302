import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import AuthRoute from './components/AuthRoute';
import AdminRoute from './components/AdminRoute';

// Components
import Layout from './components/hoc/Layout';
import BasePage from './components/hoc/BasePage';
import FullPageLoader from './components/FullPageLoader';

// Views
import InformationPage from './views/InformationPage';
import FormsListPage from './views/Forms/FormsListPage';
import EditForm from './views/Forms/EditForm';
import EditFormEditor from './views/Forms/EditFormEditor';
import TriggersListPage from './views/Triggers/TriggersListPage';
import TriggerEditPage from './views/Triggers/TriggerEditPage';
import LoginPage from './views/LoginPage';
import RegisterPage from './views/RegisterPage';
import RecoverPage from './views/RecoverPage';
import RecoverNewPage from './views/RecoverNewPage';
import TagsListPage from './views/TagsListPage';
import AudiencePage from './views/AudiencePage';
import AudienceExceptions from './views/AudienceExceptions';
import SubscriberProfilePage from './views/SubscriberProfilePage';
import ProfileIntegrationSettings from './views/Profile/ProfileIntegrationSettings';
import ProfileAccountSettings from './views/Profile/ProfileAccountSettings';
import ProfileShopSettings from './views/Profile/ProfileShopSettings';
import ConfirmRegister from './views/ConfirmRegister';
import ConfirmEmailPage from './views/ConfirmEmailPage';
import PaymentPage from './views/PaymentPage';
import PaymentSuccessPage from './views/PaymentSuccessPage';
import PaymentErrorPage from './views/PaymentErrorPage';
import NotFound from './views/NotFound';

import LoginAdminPage from './views/Admin/LoginAdminPage';
import AdminUsersListPage from './views/Admin/AdminUsersListPage';
import AdminUserProfilePage from './views/Admin/AdminUserProfilePage';
import AdminModerationPage from './views/Admin/AdminModerationPage';
import AdminModerationFormPage from './views/Admin/AdminModerationFormPage';
import AdminModerationLetterPage from './views/Admin/AdminModerationLetterPage';
import AdminPaymentPage from './views/Admin/AdminPaymentPage';
import AdminAudienceExceptionsPage from './views/Admin/AdminAudienceExceptionsPage';

class Routes extends Component {
  render () {
    let routes = null;

    if (this.props.authPending) {
      routes = <FullPageLoader />;

      return routes;
    }

    return (
      <Switch>
        <AuthRoute
          path="/login"
          component={(props) => (
            <BasePage {...props}>
              <LoginPage {...props} />
            </BasePage>
          )}
          exact
        />

        <AuthRoute
          path="/admin/login"
          component={(props) => (
            <BasePage {...props}>
              <LoginAdminPage {...props} />
            </BasePage>
          )}
          exact
        />

        <AuthRoute
          path="/register"
          component={(props) => (
            <BasePage {...props}>
              <RegisterPage {...props} />
            </BasePage>
          )}
          exact
        />

        <AuthRoute
          path="/register/confirm"
          component={(props) => (
            <BasePage {...props}>
              <ConfirmRegister {...props} />
            </BasePage>
          )}
          exact
        />

        <AuthRoute
          path="/recover"
          component={(props) => (
            <BasePage {...props}>
              <RecoverPage {...props} />
            </BasePage>
          )}
          exact
        />

        <AuthRoute
          path="/recover/new"
          component={(props) => (
            <BasePage {...props}>
              <RecoverNewPage {...props} />
            </BasePage>
          )}
          exact
        />

        <PrivateRoute
          path="/email/confirm"
          component={(props) => (
            <BasePage {...props}>
              <ConfirmEmailPage {...props} />
            </BasePage>
          )}
          exact
        />

        <PrivateRoute
          path="/"
          component={(props) => (
            <Layout {...props}>
              <InformationPage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/subscriptions"
          component={(props) => (
            <Layout {...props}>
              <FormsListPage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/form/:formId/edit"
          component={(props) => (
            <Layout {...props}>
              <EditForm {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/form/:formId/editor/edit"
          component={(props) => (
            <Layout {...props}>
              <EditFormEditor {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/triggers"
          component={(props) => (
            <Layout {...props}>
              <TriggersListPage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/trigger/:triggerId/edit"
          component={(props) => (
            <Layout {...props}>
              <TriggerEditPage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/tags"
          component={(props) => (
            <Layout {...props}>
              <TagsListPage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/audience"
          component={(props) => (
            <Layout {...props}>
              <AudiencePage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/audience/exceptions"
          component={(props) => (
            <Layout {...props}>
              <AudienceExceptions {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/audience/profile/:profileId/:uuid"
          component={(props) => (
            <Layout {...props}>
              <SubscriberProfilePage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/profile/settings/account"
          component={(props) => (
            <Layout {...props}>
              <ProfileAccountSettings {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/profile/settings/shop"
          component={(props) => (
            <Layout {...props}>
              <ProfileShopSettings {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/profile/settings/integration"
          component={(props) => (
            <Layout {...props}>
              <ProfileIntegrationSettings {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/payment"
          component={(props) => (
            <Layout {...props}>
              <PaymentPage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/payment/success"
          component={(props) => (
            <Layout {...props}>
              <PaymentSuccessPage {...props} />
            </Layout>
          )}
          exact
        />

        <PrivateRoute
          path="/payment/error"
          component={(props) => (
            <Layout {...props}>
              <PaymentErrorPage {...props} />
            </Layout>
          )}
          exact
        />

        <AdminRoute
          path="/admin/users"
          component={(props) => (
            <Layout {...props}>
              <AdminUsersListPage {...props} />
            </Layout>
          )}
          exact
        />

        <AdminRoute
          path="/admin/users/profile/:userId"
          component={(props) => (
            <Layout {...props}>
              <AdminUserProfilePage {...props} />
            </Layout>
          )}
          exact
        />

        <AdminRoute
          path="/admin/audience/exceptions"
          component={(props) => (
            <Layout {...props}>
              <AdminAudienceExceptionsPage {...props} />
            </Layout>
          )}
          exact
        />

        <AdminRoute
          path="/admin/payment"
          component={(props) => (
            <Layout {...props}>
              <AdminPaymentPage {...props} />
            </Layout>
          )}
          exact
        />

        <AdminRoute
          path="/admin/moderation"
          component={(props) => (
            <Layout {...props}>
              <AdminModerationPage {...props} />
            </Layout>
          )}
          exact
        />

        <AdminRoute
          path="/admin/moderation/form/:userId"
          component={(props) => (
            <Layout {...props}>
              <AdminModerationFormPage {...props} />
            </Layout>
          )}
          exact
        />

        <AdminRoute
          path="/admin/moderation/letter/:userId"
          component={(props) => (
            <Layout {...props}>
              <AdminModerationLetterPage {...props} />
            </Layout>
          )}
          exact
        />

        <Redirect
          exact
          path="/admin"
          to="/admin/users"
        />

        <Route
          component={(props) => (
            <BasePage {...props}>
              <NotFound {...props} />
            </BasePage>
          )}
        />
      </Switch>
    );
  }
}

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    authPending: state.auth.authPending
  }),
  null
)(withRouter(Routes));
