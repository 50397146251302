import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  userLogin, 
  logoutAction,
  authCheckingPending, 
  setUserData 
} from './store/actions/actions';
import { BrowserRouter } from 'react-router-dom';
import { configureAnchors } from 'react-scrollable-anchor';
import {
  authAdminCheck,
  authCheck,
} from 'api';
import axios from 'utils/axios';

// Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";

import './assets/sass/styles.sass';

class App extends Component {

  componentDidMount () {
    this.appSettings();
    this.authCheck();
  }

  appSettings = () => {
    configureAnchors({ offset: -50 });

    const { logoutAction } = this.props;

    axios.interceptors.response.use((response) => {
      let logout = false;

      if (response.data.error) {
        switch (response.data.error) {
          case 'Token not provided':
            logout = true;

            break;

          case 'TOKEN_INVALID':
            logout = true;

            break;

          case 'TOKEN_EXPIRED':
            logout = true;

            break;

          case 'TOKEN_BLACKLISTED':
            logout = true;

            break;

          default:
        }
      }

      if (logout) {
        logoutAction();
      }

      return response;
    });
  };

  authCheck = () => {
    const { 
      authCheckingPending,
      userLogin,
      setUserData,
      isAdminAuth
    } = this.props;

    const token = localStorage.getItem('authToken');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
    const authCheckApiUrl = isAdminAuth ? authAdminCheck() : authCheck();

    if (token) {
      authCheckingPending(true);

      authCheckApiUrl
        .then(response => {
          authCheckingPending(false);

          if (response.data.success) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            
            userLogin(token, isAdminAuth);
            setUserData(response.data.data.id, response.data.data.name, response.data.data.email);
          }
        })
        .catch(error => {
          authCheckingPending(false);
        });
    }
  };

  render () {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }
}

export default connect(
  state => ({
    isAdminAuth: state.settings.isAdminAuth
  }),
  { 
    userLogin, 
    logoutAction,
    authCheckingPending, 
    setUserData 
  }
)(App);
