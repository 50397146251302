import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = props => (
  <div className="abs-center wd-xl">
    <div className="text-center mb-4">
      <div className="text-lg mb-3">404</div>
      
      <p className="lead m-0">Не удалось найти странциу</p>
      <p>Страница которую вы пытаетесь найти не существует</p>
    </div>
    
    <ul className="list-inline text-center text-sm mb-4">
      <li className="list-inline-item">
        <Link to="/" className="text-muted">Главная</Link>
      </li>
    
      <li className="text-muted list-inline-item">|</li>
    
      <li className="list-inline-item">
        <Link to="/login" className="text-muted">Вход</Link>
      </li>
    
      <li className="text-muted list-inline-item">|</li>
    
      <li className="list-inline-item">
        <Link to="/register" className="text-muted">Регистрация</Link>
      </li>
    </ul>
  </div>
)

export default NotFound;
