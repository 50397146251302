import axios from 'axios';

export let baseURL = '';

switch (process.env.NODE_ENV) {
  case 'development':
    baseURL = 'https://bk.boostmy.email/api';
    break;
  
  case 'production':
    baseURL = 'https://bk.boostmy.email/api';
    break;
  
  default:
}

const instance = axios.create({
  baseURL
});

export default instance;
