import React from 'react';

// Components
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';

const Layout = props => (
	<div className="wrapper">
		<Header />
		
		<Sidebar />
		
		<main className="section-container">
			{props.children}
		</main>		
	</div>
);

export default Layout;
